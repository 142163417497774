<template>
  <p
    class="alert"
    :class="{
      'alert--primary': type === 'primary',
      'alert--danger': type === 'danger',
      'alert--center': center,
    }"
  >
    <slot />
  </p>
</template>

<script>
export default {
  name: "BaseAlert",
  props: {
    type: String,
    center: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.alert {
  &--primary {
    color: $primary;
    font-size: 16px;
  }
  &--danger {
    color: $red;
    font-size: 16px;
  }
  &--center {
    text-align: center;
  }
}
</style>
