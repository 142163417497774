<template>
  <base-page class="home-page" has-menu>
    <base-loader :loading="loading" />
    <base-header> Копилки </base-header>
    <base-container>
      <premium-window title="Новые возможности с Premium за" />
    </base-container>
    <base-container @click.native="$router.push({ name: 'FormStorageAdd' })"
      ><base-button class="btn--primary btn--block mb-16"
        >СОЗДАТЬ КОПИЛКУ</base-button
      ></base-container
    >
    <base-container v-if="currentStorages && currentStorages.length > 0">
      <storage-item
        v-for="item in currentStorages"
        :key="`storage-${item.id}`"
        :id="item.id"
        :name="item.name"
        :days="item.days"
        :icon="item.icon"
        :color="item.color"
        :summ="item.summ"
        :need-summ="item.need_summ"
        @openActionModal="openActionModal"
      />
    </base-container>
    <base-container v-if="archivedStorages && archivedStorages.length > 0">
      <h2 class="home-page__subtitle">В архиве</h2>
      <storage-item
        v-for="item in archivedStorages"
        :key="`storage-${item.id}`"
        :id="item.id"
        :name="item.name"
        :days="item.days"
        :icon="item.icon"
        :color="item.color"
        :summ="item.summ"
        :need-summ="item.need_summ"
        :archive="item.in_archive"
        @openActionModal="openActionModal"
      />
    </base-container>
    <base-container>
      <market-thnx />
    </base-container>
    <vue-bottom-sheet :rounded="isIos" ref="storageActionModal">
      <div class="action-menu">
        <a @click="editStorage" class="action-menu__link">
          <svg-icon name="pencil" />
          Редактировать
        </a>
        <a
          @click="
            selectedStorageData.in_archive === 1
              ? moveFromArchive()
              : moveToArchive()
          "
          class="action-menu__link"
        >
          <svg-icon name="folder_open" />
          <template
            v-if="selectedStorageData && selectedStorageData.in_archive === 1"
          >
            Вернуть из архива
          </template>
          <template v-else> Переместить в архив </template>
        </a>
        <a
          @click="deleteStorageConfirm"
          class="action-menu__link action-menu__link--danger"
          href="#"
        >
          <svg-icon name="delete" />
          Удалить
        </a>
      </div>
    </vue-bottom-sheet>
    <vue-bottom-sheet :rounded="isIos" ref="deleteStorageSheet">
      <div class="premium__info">
        <p>Вы действительно хотите удалить копилку?</p>
        <base-button
          @click.native="deleteStorage"
          button-type="button"
          class="btn--primary btn--block"
          >Да</base-button
        >
        <base-button
          @click.native="$refs.deleteStorageSheet.close()"
          button-type="button"
          class="btn--danger btn--block"
          >Нет</base-button
        >
      </div>
    </vue-bottom-sheet>
  </base-page>
</template>

<script>
import BaseHeader from "@/components/base/BaseHeader";
import BasePage from "@/components/base/BasePage";
import SvgIcon from "@/components/SvgIcon";
import BaseContainer from "@/components/base/BaseContainer";
import MarketThnx from "@/components/MarketThnx";
import StorageItem from "@/components/StorageItem";
import BaseLoader from "@/components/base/BaseLoader";
import { mapGetters, mapState } from "vuex";
import axios from "axios";
import VueBottomSheet from "@webzlodimir/vue-bottom-sheet";
import { startOneSinglaSubscription } from "@/helpers";
import PremiumWindow from "@/components/PremiumWindow";
import BaseButton from "@/components/base/BaseButton";
import { Device } from "@capacitor/device";
export default {
  name: "Home",
  components: {
    BaseButton,
    PremiumWindow,
    MarketThnx,
    BaseLoader,
    StorageItem,
    BaseContainer,
    SvgIcon,
    BasePage,
    BaseHeader,
    VueBottomSheet,
  },
  data() {
    return {
      storages: [],
      selectedStorage: null,
      loading: false,
    };
  },
  created() {
    this.getStorages();
  },
  computed: {
    ...mapGetters(["isIos", "premiumState", "isWeb"]),
    ...mapState(["user"]),
    currentStorages() {
      return this.storages.filter((el) => {
        return el.in_archive === 0;
      });
    },
    archivedStorages() {
      return this.storages.filter((el) => {
        return el.in_archive === 1;
      });
    },
    selectedStorageData() {
      return this.storages.find((el) => {
        return el.id === this.selectedStorage;
      });
    },
  },
  watch: {
    premiumState(newValue) {
      if (newValue === "owned") {
        axios.post("/user/update", { premium: 1 }).then(() => {
          this.$store.dispatch("UPDATE_USER");
        });
      }
    },
  },
  mounted() {
    if (this.premiumState === "owned") {
      axios.post("/user/update", { premium: 1 }).then(() => {
        this.$store.dispatch("UPDATE_USER");
      });
    }
    Device.getInfo().then((device) => {
      if (device.platform !== "web") {
        startOneSinglaSubscription(this.$store.state.user.id);
      } else {
        window.OneSignal = window.OneSignal || [];
        window.OneSignal.push(() => {
          window.OneSignal.init({
            appId: "c2f4aed0-fa64-4466-aef4-a0607aa2c12c",
            safari_web_id:
              "web.onesignal.auto.43f3e2d2-3818-43ea-aa50-e469d0e1e106",
          });
          window.OneSignal.setExternalUserId(this.$store.state.user.id);
          window.OneSignal.on("notificationPermissionChange", function () {
            setTimeout(() => {
              location.reload();
            }, 500);
          });
        });
      }
    });
    document.title = "Копилки";
  },
  methods: {
    moveToArchive() {
      axios.post("/storage/archive", { id: this.selectedStorage }).then((r) => {
        if (r.data.result === "success") {
          this.getStorages();
          this.$refs.storageActionModal.close();
        }
      });
    },
    moveFromArchive() {
      axios
        .post("/storage/unarchive", { id: this.selectedStorage })
        .then((r) => {
          if (r.data.result === "success") {
            this.getStorages();
            this.$refs.storageActionModal.close();
          }
        });
    },
    getStorages() {
      this.loading = true;
      axios
        .get("/storage/get")
        .then((r) => {
          if (r.data.result === "success") {
            this.storages = r.data.data;
            this.loading = false;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    openActionModal(id) {
      this.selectedStorage = id;
      this.$refs.storageActionModal.open();
    },
    editStorage() {
      this.$refs.storageActionModal.close();
      setTimeout(() => {
        this.$router.push({
          name: "FormStorageEdit",
          params: { id: this.selectedStorage },
        });
      }, 300);
    },
    deleteStorageConfirm() {
      this.$refs.storageActionModal.close();
      setTimeout(() => {
        this.$refs.deleteStorageSheet.open();
      }, 270);
    },
    deleteStorage() {
      axios.post("/storage/delete", { id: this.selectedStorage }).then((r) => {
        if (r.data.result === "success") {
          this.getStorages();
          this.$refs.deleteStorageSheet.close();
        }
      });
    },
  },
};
</script>

<style lang="scss">
.home-page {
  &__subtitle {
    font-weight: 600;
    font-size: 16px;
    margin-top: 32px;
    margin-bottom: 16px;
    user-select: none;
  }
  &__empty {
    user-select: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $blueGray;
    h2 {
      font-weight: 400;
    }
    .svg-icon {
      width: 150px;
      height: 150px;
    }
  }

  &__maps-list {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .page {
    padding-top: 78px;
    padding-bottom: 16px;
  }
}
.action-menu {
  display: flex;
  flex-direction: column;

  &__link {
    display: flex;
    align-items: center;
    color: $secondary;
    font-size: 16px;
    text-decoration: none;
    padding: 16px;
    font-weight: 600;
    &:hover,
    &:focus {
      color: $secondary;
      text-decoration: none;
    }

    &:active {
      background-color: rgba($primary, 0.1);
    }

    svg {
      margin-right: 16px;
      fill: currentColor;
    }
    &--danger {
      color: $red;
      &:hover,
      &:active,
      &:focus {
        color: $red;
        text-decoration: none;
      }
    }

    & + .action-menu__link {
      border-top: 1px solid #e2e9ff;
    }
  }
}

.dark {
  .home-page {
    &__subtitle {
      color: $grey;
    }
  }
  .premium__info {
    p {
      color: #ffffff;
    }
  }
  .action-menu {
    &__link {
      color: $blueGray;
      &--danger {
        color: $red;
      }
      & + .action-menu__link {
        border-top: 0;
      }
    }
  }
}
</style>
