<template>
  <div class="message">
    <div class="message__title">
      {{ user }}
    </div>
    <div class="message__text">
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseMessage",
  props: {
    user: String,
    text: String,
  },
};
</script>

<style scoped lang="scss">
.message {
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  background: $primary;
  color: #fff;
  padding: 16px;

  & + .message {
    margin-top: 16px;
  }

  &__title {
    font-weight: bold;
    font-size: 16px;
  }
}
</style>
