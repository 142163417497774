<template>
  <base-page has-menu class="profile-page">
    <base-header>
      <template #default>Профиль</template>
      <template #action>
        <svg-icon @click.native="openMenu" name="dots" />
      </template>
    </base-header>
    <base-container>
      <div class="profile-item">
        <div class="profile-item__img">
          <svg-icon name="smile" />
        </div>
        <p class="profile-item__name">
          {{ userName }}
          <svg-icon
            @click.native="$refs.infoSheet.open()"
            v-if="isPremium"
            name="crown"
          />
        </p>
        <p v-if="userNickname" class="profile-item__phone">
          @{{ userNickname }}
        </p>
        <p v-else class="profile-item__phone">
          {{ userPhone }}
        </p>
      </div>
      <div class="profile-page__settings">
        <div class="settings-item">
          <svg-icon class="settings-item__icon" name="group" />
          <span class="settings-item__title"> Пол </span>
          <span class="settings-item__value">
            <template v-if="userGender">{{ userGender }}</template>
            <template v-else>Не заполнено</template>
          </span>
        </div>
        <div class="settings-item">
          <svg-icon class="settings-item__icon" name="calendar" />
          <span class="settings-item__title"> Дата рождения </span>
          <span class="settings-item__value">
            <template v-if="userBirthday">
              {{ userBirthday }}
            </template>
            <template v-else>Не заполнено</template>
          </span>
        </div>
        <router-link
          :to="{ name: 'ProfilePhone' }"
          class="settings-item settings-item--link"
        >
          <svg-icon class="settings-item__icon" name="phone" />
          <span class="settings-item__title"> Номер телефона </span>
          <span class="settings-item__value">
            <template v-if="userPhone">
              {{ userPhone }}
            </template>
            <template v-else>Не заполнено</template>
          </span>
          <span class="settings-item__arrow">
            <svg-icon name="chevron-left" />
          </span>
        </router-link>
        <router-link
          :to="{ name: 'ProfileEmail' }"
          class="settings-item settings-item--link"
        >
          <svg-icon class="settings-item__icon" name="email" />
          <span class="settings-item__title"> E-mail </span>
          <span class="settings-item__value">
            <template v-if="userEmail">
              {{ userEmail }}
            </template>
            <template v-else>Не заполнено</template>
          </span>
          <span class="settings-item__arrow">
            <svg-icon name="chevron-left" />
          </span>
        </router-link>
        <router-link
          :to="{ name: 'ProfilePassword' }"
          class="settings-item settings-item--link"
        >
          <svg-icon class="settings-item__icon" name="https" />
          <span class="settings-item__title"> Пароль </span>
          <span class="settings-item__value">
            <template v-if="userHasPassword"> Установлен </template>
            <template v-else>Не установлен</template>
          </span>
          <span class="settings-item__arrow">
            <svg-icon name="chevron-left" />
          </span>
        </router-link>
        <base-container v-if="!isWeb">
          <base-button
            :loading="loading"
            @click.native="$refs.confirmVkRemoveSheet.open()"
            v-if="user.vk_id"
            class="btn--danger btn--block"
            >Открепить аккаунт Вконтакте</base-button
          >
          <base-button
            :loading="loading"
            @click.native="addVkAccount"
            v-else
            class="btn--primary-dark btn--block"
            >Прикрепить аккаунт Вконтакте</base-button
          >
          <base-button
            :loading="loading"
            @click.native="$refs.confirmGoogleRemoveSheet.open()"
            v-if="user.google_id"
            class="btn--danger btn--block"
            >Открепить аккаунт Google</base-button
          >
          <base-button
            :loading="loading"
            @click.native="addGoogleAccount"
            v-else
            class="btn--primary btn--block"
            >Прикрепить аккаунт Google</base-button
          >
          <base-alert :center="true" type="danger">{{ errorText }}</base-alert>
        </base-container>
      </div>
    </base-container>
    <vue-bottom-sheet :rounded="isIos" ref="profileBottomSheet">
      <div class="profile-menu">
        <a @click="closeMenu()" class="profile-menu__link">
          <svg-icon name="pencil" />
          Редактировать профиль
        </a>
        <a
          class="profile-menu__link profile-menu__link--danger"
          href="#"
          @click.prevent="logoutConfirm"
        >
          <svg-icon name="exit" />
          Выйти из аккаунта
        </a>
      </div>
    </vue-bottom-sheet>
    <vue-bottom-sheet :rounded="isIos" ref="infoSheet">
      <div class="premium__info">
        <svg-icon name="crown" />
        <p>
          Premium навсегда отключает рекламу в приложении, даёт Вам доступ к
          расширенному режиму создания копилок в котором вы можете менять
          количество дней накопления, шаг накопления и прочее. И у Вас он уже
          есть!
        </p>
        <base-button
          @click.native="$refs.infoSheet.close()"
          button-type="button"
          class="btn--danger btn--block"
          >Понятно</base-button
        >
      </div>
    </vue-bottom-sheet>
    <vue-bottom-sheet :rounded="isIos" ref="confirmLogoutSheet">
      <div class="premium__info">
        <p>Вы действительно хотите выйти из аккаунта?</p>
        <base-button
          @click.native="logout"
          button-type="button"
          class="btn--primary btn--block"
          >Да</base-button
        >
        <base-button
          @click.native="$refs.confirmLogoutSheet.close()"
          button-type="button"
          class="btn--danger btn--block"
          >Нет</base-button
        >
      </div>
    </vue-bottom-sheet>
    <vue-bottom-sheet :rounded="isIos" ref="confirmVkRemoveSheet">
      <div class="premium__info">
        <p>Вы действительно хотите открепить аккаунт Вконтакте?</p>
        <base-button
          @click.native="removeVkAccount"
          button-type="button"
          class="btn--primary btn--block"
          >Да</base-button
        >
        <base-button
          @click.native="$refs.confirmVkRemoveSheet.close()"
          button-type="button"
          class="btn--danger btn--block"
          >Нет</base-button
        >
      </div>
    </vue-bottom-sheet>
    <vue-bottom-sheet :rounded="isIos" ref="confirmGoogleRemoveSheet">
      <div class="premium__info">
        <p>Вы действительно хотите открепить аккаунт Google?</p>
        <base-button
          @click.native="removeGoogleAccount"
          button-type="button"
          class="btn--primary btn--block"
          >Да</base-button
        >
        <base-button
          @click.native="$refs.confirmGoogleRemoveSheet.close()"
          button-type="button"
          class="btn--danger btn--block"
          >Нет</base-button
        >
      </div>
    </vue-bottom-sheet>
  </base-page>
</template>

<script>
import BaseHeader from "@/components/base/BaseHeader";
import BasePage from "@/components/base/BasePage";
import BaseContainer from "@/components/base/BaseContainer";
import VueBottomSheet from "@webzlodimir/vue-bottom-sheet";
import { mapGetters, mapState } from "vuex";
import SvgIcon from "@/components/SvgIcon";
import BaseButton from "@/components/base/BaseButton";
import { VKAuth } from "capacitor-plugin-vk-auth";
import axios from "axios";
import store from "@/store";
import BaseAlert from "@/components/base/BaseAlert";
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
export default {
  name: "ProfilePage",
  data() {
    return {
      loading: false,
      errorText: null,
    };
  },
  components: {
    BaseAlert,
    BaseButton,
    SvgIcon,
    VueBottomSheet,
    BaseContainer,
    BasePage,
    BaseHeader,
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters(["isIos", "isPremium", "isWeb"]),
    userName() {
      return this.user?.name;
    },
    userPhone() {
      return this.user?.phone;
    },
    userEmail() {
      return this.user?.email;
    },
    userHasPassword() {
      return this.user?.hasPassword;
    },
    userNickname() {
      return this.user?.nickname;
    },
    userGender() {
      return this.user?.gender;
    },
    userBirthday() {
      return this.user?.birthday;
    },
  },
  created() {
    this.$store.dispatch("UPDATE_USER");
  },
  mounted() {
    document.title = "Профиль";
  },
  methods: {
    addVkAccount() {
      this.loading = true;
      VKAuth.initWithId({ id: "7873064" });
      VKAuth.auth({ scope: ["offline"] });
      const { remove } = VKAuth.addListener("vkAuthFinished", (info) => {
        if (info.result === true) {
          axios.post("/login-vk/update", { vkId: info.userId }).then((r) => {
            if (r.data.result === "success") {
              axios.get("/user").then((response) => {
                store.commit("SetUser", response.data);
                this.loading = false;
                this.errorText = null;
              });
              remove();
            } else {
              this.loading = false;
              this.errorText = r.data.errorText;
            }
          });
        }
      });
    },
    removeVkAccount() {
      this.$refs.confirmVkRemoveSheet.close();
      this.loading = true;
      axios.post("/login-vk/remove").then((r) => {
        if (r.data.result === "success") {
          axios.get("/user").then((response) => {
            store.commit("SetUser", response.data);
            this.loading = false;
            this.errorText = null;
          });
        } else {
          this.loading = false;
          this.errorText = r.data.errorText;
        }
      });
    },
    addGoogleAccount() {
      this.loading = true;
      GoogleAuth.signIn().then((r) => {
        axios
          .post("/login-google/update", { googleId: r.id })
          .then((loginResponse) => {
            if (loginResponse.data.result === "success") {
              axios.get("/user").then((response) => {
                store.commit("SetUser", response.data);
                this.loading = false;
                this.errorText = null;
              });
            } else {
              this.loading = false;
              this.errorText = loginResponse.data.errorText;
            }
          });
      });
    },
    removeGoogleAccount() {
      this.$refs.confirmGoogleRemoveSheet.close();
      this.loading = true;
      axios.post("/login-google/remove").then((r) => {
        if (r.data.result === "success") {
          axios.get("/user").then((response) => {
            store.commit("SetUser", response.data);
            this.loading = false;
            this.errorText = null;
          });
        } else {
          this.loading = false;
          this.errorText = r.data.errorText;
        }
      });
    },
    openMenu() {
      this.$refs.profileBottomSheet.open();
    },
    closeMenu() {
      this.$refs.profileBottomSheet.close();
      setTimeout(() => {
        this.$router.push({ name: "ProfileEdit" });
      }, 270);
    },
    logoutConfirm() {
      this.$refs.profileBottomSheet.close();
      setTimeout(() => {
        this.$refs.confirmLogoutSheet.open();
      }, 270);
    },
    logout() {
      this.$store.commit("LogOut");
    },
  },
};
</script>

<style lang="scss">
.profile-page {
  &__settings {
    margin-left: -16px;
    margin-right: -16px;
  }
  .btn + .btn {
    margin-top: 16px;
  }
  .alert {
    margin-top: 16px;
  }
}
.profile-item {
  display: grid;
  grid-template-columns: 72px auto;
  grid-template-rows: 1fr 1fr;
  column-gap: 16px;
  justify-content: start;
  padding-bottom: 32px;
  &__name {
    font-size: 20px;
    color: $secondary;
    font-weight: 600;
    margin-bottom: 0;
    align-self: end;
    display: flex;
    align-items: center;

    .svg-icon {
      margin-left: 8px;
    }
  }
  &__phone {
    font-size: 16px;
    color: $grey;
    font-weight: 400;
    margin-bottom: 0;
    align-self: start;
  }
  &__img {
    width: 72px;
    height: 72px;
    overflow: hidden;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    grid-row: span 2;

    .svg-icon {
      width: 100%;
      height: 100%;
      fill: $primary;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.settings-item {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 16px;
  text-decoration: none;

  &--link {
    &:active {
      background-color: rgba(0, 188, 212, 0.2);
    }
  }

  &__arrow {
    transform: rotate(180deg);
  }

  &__icon {
    fill: $primary;
    margin-right: 16px;
  }

  &__title {
    font-weight: 600;
    font-size: 16px;
    color: $secondary;
  }

  &__value {
    margin-left: auto;
    color: $grey;

    & + .settings-item__arrow {
      margin-left: 16px;
      margin-right: -8 px;
    }
  }
}
.profile-menu {
  display: flex;
  flex-direction: column;

  &__link {
    display: flex;
    align-items: center;
    color: $secondary;
    font-size: 16px;
    text-decoration: none;
    padding: 16px;
    font-weight: 600;
    &:hover,
    &:focus {
      color: $secondary;
      text-decoration: none;
    }

    &:active {
      background-color: rgba($primary, 0.1);
    }

    svg {
      margin-right: 16px;
      fill: currentColor;
    }
    &--danger {
      color: $red;
      &:hover,
      &:active,
      &:focus {
        color: $red;
        text-decoration: none;
      }
    }

    & + .profile-menu__link {
      border-top: 1px solid #e2e9ff;
    }
  }
}

.dark {
  .profile-item {
    &__name {
      color: $blueGray;
    }
    &__img {
      .svg-icon {
        fill: $primaryDark;
      }
    }
  }
  .settings-item {
    &__icon {
      fill: $primaryDark;
    }
    &__title,
    &__value {
      color: $blueGray;
    }
  }
  .profile-menu {
    &__link {
      color: $blueGray;
      &--danger {
        color: $red;
      }
      & + .profile-menu__link {
        border-top: 0;
      }
    }
  }
}
</style>
