<template>
  <div
    class="input-group"
    :class="{
      'input-group--error': error.status,
      'input-group--block': block,
      'input-group--focused': focused,
      'input-group--compact': compact,
    }"
  >
    <div class="input-group__wrapper">
      <label v-if="label && label.length > 0" class="input-group__label"
        >{{ label }} <span v-if="required">*</span></label
      >
      <div class="input-group__select">
        <select
          class="input-group__input"
          :value="value"
          @focus="focused = true"
          @blur="focused = false"
          @change="$emit('input', $event.target.value)"
        >
          <option
            v-for="(option, idx) in options"
            :key="`option-${idx}`"
            :value="option.value"
          >
            {{ option.name }}
          </option>
        </select>
      </div>
    </div>
    <div v-if="error.status" class="input-group__error">
      {{ error.text }}
    </div>
    <p v-if="description" class="input-group__input-description">
      {{ description }}
    </p>
  </div>
</template>
<script>
import inputMixins from "@/components/base/inputs/inputMixins";
export default {
  mixins: [inputMixins],
  name: "BaseSelect",
};
</script>

<style lang="scss" scoped>
@import "inputStyles";
</style>
