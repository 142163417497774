<template>
  <transition name="fade">
    <div v-if="showNav" class="bottom-nav">
      <router-link class="bottom-nav__link" :to="{ name: 'Home' }">
        <svg-icon name="account_balance_wallet" />
        <span>Копилки</span>
        <span class="ripple"></span>
      </router-link>
      <router-link class="bottom-nav__link" :to="{ name: 'Profile' }">
        <svg-icon name="smile" />
        <span>Профиль</span>
        <span class="ripple"></span>
      </router-link>
      <router-link class="bottom-nav__link" :to="{ name: 'Settings' }">
        <svg-icon name="settings" />
        <span>Настройки</span>
        <span class="ripple"></span>
      </router-link>
      <router-link class="bottom-nav__link" :to="{ name: 'Help' }">
        <svg-icon name="menu_book" />
        <span>Помощь</span>
        <span class="ripple"></span>
      </router-link>
    </div>
  </transition>
</template>

<script>
import { mapState } from "vuex";
import SvgIcon from "@/components/SvgIcon";

export default {
  name: "MainNav",
  components: { SvgIcon },
  computed: {
    showNav() {
      return !this.$route.meta.noMenu;
    },
    ...mapState(["user", "keyboardShown"]),
  },
};
</script>

<style lang="scss">
.bottom-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #ffffff;
  z-index: 500;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: space-between;
  transition: 0.4s;

  &__link {
    text-align: center;
    text-decoration: none;
    padding: 16px;
    position: relative;
    overflow: hidden;

    .ripple {
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      border-radius: 50%;
      transform: scale(0);
      background-color: rgba(0, 188, 212, 0.2);
    }

    .svg-icon {
      max-width: 25px;
      max-height: 25px;
      fill: $blueGray;
      transition: fill 0.8s ease;
    }
    &:focus,
    &:active,
    &.router-link-exact-active,
    &.router-link-active {
      .svg-icon {
        fill: $primary;
      }
      span {
        color: $primary;
        font-size: 14px;
      }
    }
    span {
      display: block;
      color: $blueGray;
      font-size: 12px;
      font-weight: 600;
      font-style: normal;
      line-height: normal;
      text-align: center;
      margin-top: 6px;
      transition: 0.4s;
    }

    &:active {
      .ripple {
        animation: ripple 300ms linear;
      }
    }
  }
}
.ios-stripe .bottom-nav {
  padding-bottom: 15px;
  height: auto;
}

@keyframes ripple {
  to {
    transform: scale(4);
    opacity: 0;
  }
}

.dark {
  .bottom-nav {
    background: #192734;

    &__link {
      &:focus,
      &:active,
      &.router-link-exact-active,
      &.router-link-active {
        .svg-icon {
          fill: $primaryDark;
        }
        span {
          color: $primaryDark;
        }
      }
    }
  }
}
</style>
