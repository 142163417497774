var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-page',[_c('base-header',{attrs:{"no-scroll":true},nativeOn:{"click":function($event){return _vm.$router.push({
        name: 'Profile',
      })}},scopedSlots:_vm._u([{key:"back",fn:function(){return [_c('svg-icon',{attrs:{"name":"chevron-left"}})]},proxy:true},{key:"default",fn:function(){return [_vm._v("Изменение пароля")]},proxy:true}])}),_c('base-container',[_c('form',{attrs:{"action":"#"},on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('base-input',{attrs:{"block":true,"input-type":"password","label":"Новый пароль","error":{
          status: _vm.$v.password.$error,
          text: 'Введите пароль',
        }},model:{value:(_vm.$v.password.$model),callback:function ($$v) {_vm.$set(_vm.$v.password, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.password.$model"}}),_c('base-input',{attrs:{"block":true,"input-type":"password","label":"Повторите новый пароль","error":{
          status: _vm.$v.repeatPassword.$error,
          text: !_vm.$v.repeatPassword.sameAsPassword
            ? 'Пароли не совпадают'
            : 'Введите пароль',
        }},model:{value:(_vm.$v.repeatPassword.$model),callback:function ($$v) {_vm.$set(_vm.$v.repeatPassword, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.repeatPassword.$model"}}),_c('base-button',{staticClass:"btn--primary btn--block",attrs:{"loading":_vm.loading,"button-type":"submit"}},[_vm._v(" Сохранить ")])],1),(_vm.serverError)?_c('base-alert',{staticStyle:{"margin-top":"32px"},attrs:{"type":"danger","center":true}},[_vm._v(" "+_vm._s(_vm.serverError)+" ")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }