<template>
  <base-page has-menu class="profile-page">
    <base-header
      :no-scroll="true"
      @click.native="$router.push({ name: 'Profile' })"
    >
      <template #back>
        <svg-icon name="chevron-left" />
      </template>
      <template #default> Изменение профиля </template>
    </base-header>
    <base-container>
      <form class="profile-page__form" method="POST" @submit.prevent="submit">
        <base-input
          :compact="true"
          :error="{
            status: $v.user.name.$error,
            text: 'Введите имя',
          }"
          v-model.trim="$v.user.name.$model"
          label="Имя"
        />
        <base-date-input
          :compact="true"
          v-model.trim="user.birthday"
          label="Дата рождения (дд.мм.гггг)"
        />
        <base-select
          :compact="true"
          label="Пол"
          :options="genders"
          v-model="user.gender"
        />
        <base-button :loading="loading" class="btn--primary btn--block"
          >Сохранить</base-button
        >
      </form>
    </base-container>
  </base-page>
</template>

<script>
import BaseHeader from "@/components/base/BaseHeader";
import BasePage from "@/components/base/BasePage";
import BaseContainer from "@/components/base/BaseContainer";
import BaseInput from "@/components/base/inputs/BaseInput";
import BaseButton from "@/components/base/BaseButton";
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import BaseSelect from "@/components/base/inputs/BaseSelect";
import BaseDateInput from "@/components/base/inputs/BaseDateInput";
import SvgIcon from "@/components/SvgIcon";
export default {
  name: "ProfileNamePage",
  components: {
    SvgIcon,
    BaseDateInput,
    BaseSelect,
    BaseButton,
    BaseInput,
    BaseContainer,
    BasePage,
    BaseHeader,
  },
  data() {
    return {
      loading: false,
      genders: [
        { value: "Мужской", name: "Мужской" },
        { value: "Женский", name: "Женский" },
      ],
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  mounted() {
    document.title = "Изменение профиля";
  },
  validations: {
    user: {
      name: {
        required,
      },
    },
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = true;
        axios
          .post("/user/update", {
            id: this.user.id,
            name: this.user.name,
            birthday: this.user.birthday,
            gender: this.user.gender,
          })
          .then((r) => {
            this.loading = false;
            if (r.data.result === "success") {
              this.$router.push({ name: "Profile" });
            }
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.profile-page {
  .container {
    padding-top: 24px;
    padding-bottom: 16px;
  }
}
</style>
