<template>
  <base-page class="storage-page">
    <base-header :no-scroll="true">
      <template #back>
        <svg-icon @click.native="$router.go(-1)" name="chevron-left" />
      </template>
      <template #default>
        <div @click="$router.go(-1)">{{ storageInfo.name }}</div>
      </template>
      <template #action>
        <svg-icon @click.native="openStorageViewMenu" name="dots" />
      </template>
    </base-header>
    <base-container>
      <div
        class="storage-grid"
        :class="`storage-grid--zoom-${storageInfo.small}`"
      >
        <template v-for="day in daysEmpty">
          <div
            :class="{
              'storage-grid__item--active': activeStorage(day),
              'storage-grid__item--hide': storageInfo.hide_checked,
            }"
            :key="`day-${day}`"
            class="storage-grid__item"
            @click="
              activeStorage(day)
                ? openDeleteModal(activeStorage(day))
                : openUpdateModal(day)
            "
          >
            {{ day }}
          </div>
        </template>
      </div>
    </base-container>
    <vue-bottom-sheet :rounded="isIos" ref="addDay">
      <div class="storage-page__menu">
        <h2 class="storage-page__title">Добавить {{ selectedDay }} р.?</h2>
        <base-button
          :loading="loading"
          @click.native="addDay(selectedDay)"
          class="btn--primary btn--block"
          >Добавить</base-button
        >
        <base-button
          @click.native="$refs.addDay.close()"
          button-type="button"
          class="btn--danger btn--block"
          >Отменить</base-button
        >
      </div>
    </vue-bottom-sheet>
    <vue-bottom-sheet :rounded="isIos" ref="deleteDay">
      <div class="storage-page__menu">
        <h2 class="storage-page__title">
          Отменить добавление {{ selectedDay && selectedDay.day }} р.?
        </h2>
        <base-button
          :loading="loading"
          @click.native="deleteDay(selectedDay.id)"
          class="btn--primary btn--block"
          >Отменить добавление</base-button
        >
        <base-button
          @click.native="$refs.deleteDay.close()"
          button-type="button"
          class="btn--danger btn--block"
          >Закрыть окно</base-button
        >
      </div>
    </vue-bottom-sheet>
    <vue-bottom-sheet :rounded="isIos" ref="existModal">
      <div class="storage-page__menu">
        <h2 class="storage-page__title">Сегодня Вы уже пополняли копилку</h2>
        <base-button
          @click.native="$refs.existModal.close()"
          button-type="button"
          class="btn--danger btn--block"
          >Вернусь завтра</base-button
        >
      </div>
    </vue-bottom-sheet>
    <vue-bottom-sheet :rounded="isIos" ref="storageViewMenu">
      <div class="storage-page__menu">
        <div class="storage-page__menu-item">
          Скрывать отмеченные
          <base-toggle-ios
            :id="1"
            @change="changeChecked"
            :true-value="1"
            :false-value="0"
            v-model="storageInfo.hide_checked"
          />
        </div>
        <div class="storage-page__menu-item">
          Маленькие кружочки
          <base-toggle-ios
            :id="2"
            @change="changeZoom"
            :true-value="1"
            :false-value="2"
            v-model="storageInfo.small"
          />
        </div>
      </div>
    </vue-bottom-sheet>
  </base-page>
</template>

<script>
import BasePage from "@/components/base/BasePage";
import BaseHeader from "@/components/base/BaseHeader";
import SvgIcon from "@/components/SvgIcon";
import axios from "axios";
import BaseContainer from "@/components/base/BaseContainer";
import BaseButton from "@/components/base/BaseButton";
import VueBottomSheet from "@webzlodimir/vue-bottom-sheet";
import BaseToggleIos from "@/components/base/BaseToggleIos";
import { mapGetters, mapState } from "vuex";
import { InterstitialAd } from "@admob-plus/capacitor";

export default {
  name: "SingleStoragePage",
  components: {
    BaseToggleIos,
    BaseButton,
    BaseContainer,
    SvgIcon,
    BaseHeader,
    BasePage,
    VueBottomSheet,
  },
  data() {
    return {
      storageInfo: {},
      selectedDay: null,
      days: [],
      daysEmpty: [],
      loading: false,
      emptyDaysCreated: false,
    };
  },
  created() {
    this.getStorageInfo();
    this.getDays();
  },
  methods: {
    getStorageInfo() {
      axios.get(`/storage/${this.$route.params.id}`).then((r) => {
        if (r.data.result === "success") {
          this.storageInfo = r.data.data;
          document.title = this.storageInfo.name;
          if (!this.emptyDaysCreated) {
            const emptyArray = new Array(r.data.data.end_on).fill(null);
            emptyArray.reduce((accum) => {
              this.daysEmpty.push(accum);
              return accum + r.data.data.step;
            }, r.data.data.start_from);
            this.emptyDaysCreated = true;
          }
        }
      });
    },
    getDays() {
      axios.get(`/day/get/${this.$route.params.id}`).then((r) => {
        if (r.data.result === "success") {
          this.days = r.data.data;
        }
      });
    },
    activeStorage(day) {
      return this.days.find((el) => el.day === day);
    },
    openUpdateModal(day) {
      if (this.user.one_in_day === 1) {
        if (this.storageInfo.today === 1) {
          this.$refs.addDay.close();
          this.$refs.existModal.open();
        } else {
          this.selectedDay = day;
          this.$refs.addDay.open();
        }
      } else {
        this.selectedDay = day;
        this.$refs.addDay.open();
      }
    },
    openDeleteModal(day) {
      this.selectedDay = day;
      this.$refs.deleteDay.open();
    },
    openStorageViewMenu() {
      this.$refs.storageViewMenu.open();
    },
    addDay(day) {
      this.loading = true;
      axios
        .post("/day/add", {
          day: {
            storage_id: this.$route.params.id,
            day: day,
          },
        })
        .then((r) => {
          this.loading = false;
          if (r.data.result === "success") {
            this.getStorageInfo();
            this.getDays();
            this.$refs.addDay.close();

            if (this.isPremium !== true || this.user.id === 10510) {
              (async () => {
                const interstitial = new InterstitialAd({
                  adUnitId: "ca-app-pub-8690020955174405/3951486906",
                });
                await interstitial.load();
                await interstitial.show();
              })();
            }
          } else if (r.data.result === "exist") {
            this.$refs.addDay.close();
            this.$refs.existModal.open();
          }
          this.$confetti.start({
            particles: [
              {
                type: "rect",
                dropRate: 10,
              },
            ],
          });
          setTimeout(() => {
            this.$confetti.stop();
          }, 1000);
        });
    },
    deleteDay(id) {
      this.loading = true;
      axios
        .post("/day/delete", {
          id: id,
        })
        .then((r) => {
          this.loading = false;
          if (r.data.result === "success") {
            this.getStorageInfo();
            this.getDays();
            this.$refs.deleteDay.close();
          }
        });
    },
    saveStorageInfo() {
      axios.post("/storage/save", { storage: this.storageInfo });
    },
    changeChecked() {
      this.saveStorageInfo();
    },
    changeZoom() {
      this.saveStorageInfo();
    },
  },
  computed: {
    ...mapGetters(["isIos", "isPremium", "isWeb"]),
    ...mapState(["user"]),
  },
};
</script>

<style scoped lang="scss">
.storage-grid {
  touch-action: pan-y !important;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 8px;

  &--zoom-1 {
    grid-template-columns: repeat(8, 1fr);
    font-size: 12px;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    font-weight: 600;
    user-select: none;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
    &:before {
      content: "";
      float: left;
      width: 1px;
      margin-left: -1px;
      padding-top: 100%;
    }
    &:after {
      content: "";
      display: table;
      clear: both;
    }
    &:active {
      background-color: rgba(0, 188, 212, 0.2);
    }
    &--active {
      border: 0;
      background: $green;
      box-shadow: none;
      color: #ffffff;

      &.storage-grid__item--hide {
        display: none;
      }
    }
  }
}
.storage-page {
  padding-bottom: 16px;

  &__menu {
    padding: 0 16px 16px 16px;

    .btn + .btn {
      margin-top: 16px;
    }

    &-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 0;

      & + .storage-page__menu-item {
        border-top: 1px solid #e2e9ff;
      }
    }
  }

  &__title {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    align-self: center;
    margin-bottom: 16px;
  }
}
.dark {
  .storage-page {
    &__title {
      color: #ffffff;
    }
    &__menu {
      &-item {
        color: $blueGray;
        font-size: 16px;
        & + .storage-page__menu-item {
          border-top: 0;
        }
      }
    }
  }
  .storage-grid {
    &__item {
      background: #192734;
      color: #ffffff;
      &:active {
        background-color: rgba(0, 188, 212, 0.2);
      }
      &--active {
        background: $primaryDark;
      }
    }
  }
}
</style>
