<template>
  <component
    :is="type"
    :type="!!href ? false : buttonType"
    :to="href"
    class="btn"
    :class="{ 'btn--disabled': disabled || loading }"
  >
    <template v-if="loading">
      <svg-icon name="smile-alt" class="btn__img" />
      Подождите
    </template>
    <slot v-else />
  </component>
</template>

<script>
import SvgIcon from "@/components/SvgIcon";
export default {
  name: "BaseButton",
  components: { SvgIcon },
  props: {
    href: {
      type: [String, Object],
      default: null,
    },
    buttonType: {
      type: [String, Boolean],
      default: "submit",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    type() {
      return this.href ? "router-link" : "button";
    },
  },
};
</script>

<style lang="scss">
.btn {
  display: inline-block;
  font-weight: 600;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  min-height: 56px;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 15px 13px;
  font-size: 16px;
  border-radius: 16px;
  transition: all 0.4s ease;

  &:active {
    transform: translate(0, 3px);
  }

  &__img {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    animation: rotating 1s linear infinite;
  }

  &--primary {
    color: #fff;
    background-color: $primary;
    border-color: $primary;
    box-shadow: 0 10px 30px rgba(64, 191, 255, 0.24);

    &:active {
      color: #fff;
      background-color: $primaryHover;
      border-color: $primaryHover;
    }
  }

  &--primary-dark {
    color: #fff;
    background-color: $primaryDark;
    border-color: $primaryDark;
    box-shadow: 0 10px 30px rgba(64, 191, 255, 0.24);

    &:active {
      color: #fff;
      background-color: $primaryHover;
      border-color: $primaryHover;
    }
  }

  &--danger {
    color: #fff;
    background-color: $red;
    border-color: $red;

    &:active {
      color: #fff;
      background-color: $red;
      border-color: $red;
    }
  }

  &--disabled {
    opacity: 0.8;
    pointer-events: none;
    &:active {
      color: #fff;
      background-color: $primary;
      border-color: $primary;
      box-shadow: 0 10px 30px rgba(64, 191, 255, 0.24);
    }
  }

  &--secondary {
    color: $grey;
    border-color: $grey;
  }

  &--block {
    width: 100%;
  }
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.dark {
  .btn {
    &--primary {
      color: #fff;
      background-color: $primaryDark;
      border-color: $primaryDark;
      box-shadow: 0 10px 30px rgba(64, 191, 255, 0.14);

      &:active {
        color: #fff;
        background-color: $primaryHover;
        border-color: $primaryHover;
      }
    }
    &--secondary {
      &:hover {
        color: #fff;
        border-color: #000;
      }
    }
  }
}
@media (min-width: 1220px) {
  .btn {
    &--primary {
      &:hover {
        background-color: $primaryHover;
      }
    }
    &--secondary {
      &:hover {
        color: #000;
        border-color: #000;
      }
    }
  }
}
</style>
