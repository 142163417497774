<template>
  <base-page :has-header="true" class="profile-page">
    <base-header>Регистрация</base-header>
    <base-container>
      <base-alert v-if="error" type="danger">
        {{ error }}
      </base-alert>
      <form class="profile-page__form" method="POST" @submit.prevent="submit">
        <base-input
          :error="{
            status: $v.name.$error,
            text: 'Введите имя',
          }"
          v-model.trim="$v.name.$model"
          placeholder="Ваше имя"
          label="Имя"
        />
        <base-input
          :error="{
            status: $v.nickname.$error,
            text: 'Введите юзернейм',
          }"
          v-model.trim="$v.nickname.$model"
          label="Юзернейм (латинские буквы)"
          placeholder="Например my-username"
        />
        <base-select label="Пол" :options="genders" v-model="gender" />
        <base-button
          button-type="submit"
          :loading="loading"
          class="btn--primary btn--block"
          >Сохранить</base-button
        >
      </form>
    </base-container>
  </base-page>
</template>

<script>
import BaseHeader from "@/components/base/BaseHeader";
import BasePage from "@/components/base/BasePage";
import BaseContainer from "@/components/base/BaseContainer";
import BaseInput from "@/components/base/inputs/BaseInput";
import BaseButton from "@/components/base/BaseButton";
import axios from "axios";
import { required, alphaNum } from "vuelidate/lib/validators";
import BaseSelect from "@/components/base/inputs/BaseSelect";
import BaseAlert from "@/components/base/BaseAlert";
export default {
  name: "RegistrationPage",
  components: {
    BaseAlert,
    BaseSelect,
    BaseButton,
    BaseInput,
    BaseContainer,
    BasePage,
    BaseHeader,
  },
  data() {
    return {
      loading: false,
      nickname: null,
      name: null,
      gender: "Мужской",
      genders: [
        { value: "Мужской", name: "Мужской" },
        { value: "Женский", name: "Женский" },
      ],
      error: null,
    };
  },
  mounted() {
    document.title = "Регистрация";
  },
  validations: {
    name: {
      required,
    },
    nickname: {
      required,
      alphaNum,
    },
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = true;
        axios
          .post("/user/registration", {
            name: this.name,
            nickname: this.nickname,
            gender: this.gender,
            registered: 1,
          })
          .then((r) => {
            this.loading = false;
            if (r.data.result === "success") {
              this.$router.push({ name: "Home" });
            } else {
              this.error = r.data.message;
            }
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>

<style lang="scss">
.profile-page {
  .container {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: scroll;
    padding-top: 16px;
    padding-bottom: 16px;
  }
}
@media (min-width: 1220px) {
  .profile-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .container {
      overflow: visible;
    }
    &__form {
      width: 400px;
      margin: 0 auto;
    }
  }
}
</style>
