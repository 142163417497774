<template>
  <base-page>
    <base-header
      :no-scroll="true"
      @click.native="
        $router.push({
          name: 'Profile',
        })
      "
    >
      <template #back>
        <svg-icon name="chevron-left" />
      </template>
      <template #default>Изменение номера телефона</template>
    </base-header>
    <base-container>
      <form @submit.prevent="submit" action="#">
        <base-phone-input
          v-if="user.phone"
          :block="true"
          :disabled="true"
          :value="user.phone"
          label="Ваш текущий номер телефона"
        />
        <base-phone-input
          :block="true"
          @change="smsWasSended = false"
          label="Введите новый номер телефона"
          :error="{
            status: $v.newPhone.$error,
            text: 'Введите номер телефона',
          }"
          v-model.trim="$v.newPhone.$model"
        />
        <base-input
          v-show="smsWasSended"
          label="Код из смс"
          :error="{
            status: $v.smsCode.$error,
            text: 'Введите код из 4 цифр',
          }"
          v-model.trim="$v.smsCode.$model"
        />
        <base-button
          :loading="loading"
          button-type="submit"
          class="btn--primary btn--block"
        >
          <template v-if="smsWasSended">Сохранить</template>
          <template v-else>Получить СМС</template>
        </base-button>
      </form>
      <base-alert
        v-if="serverError"
        style="margin-top: 32px"
        type="danger"
        :center="true"
      >
        {{ serverError }}
      </base-alert>
    </base-container>
  </base-page>
</template>

<script>
import BasePage from "@/components/base/BasePage";
import BaseHeader from "@/components/base/BaseHeader";
import SvgIcon from "@/components/SvgIcon";
import BaseContainer from "@/components/base/BaseContainer";
import BasePhoneInput from "@/components/base/inputs/BasePhoneInput";
import { mapState } from "vuex";
import {
  required,
  minLength,
  maxLength,
  numeric,
} from "vuelidate/lib/validators";
import BaseButton from "@/components/base/BaseButton";
import BaseInput from "@/components/base/inputs/BaseInput";
import axios from "axios";
import BaseAlert from "@/components/base/BaseAlert";
export default {
  name: "ProfilePhonePage",
  components: {
    BaseAlert,
    BaseInput,
    BaseButton,
    BasePhoneInput,
    BaseContainer,
    SvgIcon,
    BaseHeader,
    BasePage,
  },
  data() {
    return {
      newPhone: null,
      smsCode: null,
      smsWasSended: false,
      loading: false,
      serverError: null,
    };
  },
  validations: {
    newPhone: {
      required,
      minLength: minLength(17),
    },
    smsCode: {
      minLength: minLength(4),
      maxLength: maxLength(4),
      required,
      numeric,
    },
  },
  computed: {
    ...mapState(["user"]),
  },
  mounted() {
    document.title = "Изменение номера телефона";
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (!this.smsWasSended) {
        if (!this.$v.newPhone.$invalid) {
          this.loading = true;
          axios
            .post("/user/update-phone-sms", { phone: this.newPhone })
            .then((r) => {
              this.loading = false;
              if (r.data.result === "success") {
                this.smsWasSended = true;
                this.serverError = null;
              } else {
                this.serverError = r.data.errorText;
              }
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          this.loading = false;
        }
      } else {
        if (!this.$v.newPhone.$invalid && !this.$v.smsCode.$invalid) {
          this.loading = true;
          axios
            .post("/user/update-phone", {
              phone: this.newPhone,
              smsCode: this.smsCode,
            })
            .then((r) => {
              this.loading = false;
              if (r.data.result === "success") {
                this.$router.push({ name: "Profile" });
              } else {
                this.serverError = r.data.errorText;
              }
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          this.loading = false;
        }
      }
    },
  },
};
</script>

<style scoped></style>
