import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import router from "@/router";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    keyboardShown: false,
    token: window.localStorage.getItem("token") || null,
    user:
      window.localStorage.getItem("user") !== "undefined"
        ? JSON.parse(window.localStorage.getItem("user"))
        : {} || {},
    platform: null,
    theme: window.localStorage.getItem("theme") || "light",
    loginVariant: window.localStorage.getItem("login_variant") || "all",
    isMobile: false,
    premium: {
      price: null,
      state: null,
    },
  },
  mutations: {
    SetPremiumProduct(state, product) {
      state.premium.price = product.price;
      state.premium.state = product.state;
    },
    SetIsMobile(state, isMobile) {
      state.isMobile = isMobile;
    },
    SetLoginVariant(state, variant) {
      state.loginVariant = variant;
      window.localStorage.setItem("login_variant", variant);
    },
    SetTheme(state, theme) {
      state.theme = theme;
      window.localStorage.setItem("theme", theme);
    },
    SetPlatform(state, platform) {
      state.platform = platform;
    },
    LogIn(state, token) {
      state.token = token;
      window.localStorage.setItem("token", token);
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    },
    LogOut(state) {
      state.token = null;
      state.user = null;
      state.loginVariant = "phone";
      window.localStorage.clear();
      axios.defaults.headers.common["Authorization"] = "";
      router.push({ name: "Login" });
    },
    SetUser(state, user) {
      user.phone = `+${user.phone}`;
      state.user = user;
      window.localStorage.setItem("user", JSON.stringify(user));
    },
    UpdateUser(state, user) {
      state.user = { ...state.user, ...user };
      window.localStorage.setItem("user", JSON.stringify(user));
    },
  },
  actions: {
    UPDATE_USER({ commit }) {
      axios.get("/user").then((r) => {
        if (r.data.registered !== 1) {
          router.push("/registration");
        }
        if (r.data.phone) {
          r.data.phone = `+${r.data.phone}`;
        }
        commit("UpdateUser", r.data);
      });
    },
  },
  getters: {
    isAuthenticated: (state) => !!state.token,
    isIos: (state) => state.platform === "ios",
    isAndroid: (state) => state.platform === "android",
    isWeb: (state) => state.platform === "web",
    isPremium: (state) => state.user.premium === 1,
    premiumState: (state) => state.premium.state,
  },
});
