export default {
  data() {
    return {
      focused: false,
    };
  },
  props: {
    value: {
      type: [String, Number],
      default: "",
    },
    compact: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default() {
        return [];
      },
    },
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    inputType: {
      type: String,
      default: "",
    },
    block: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Object,
      default() {
        return {
          status: false,
          text: "",
        };
      },
    },
  },
};
