<template>
  <base-page has-menu class="help-page">
    <base-header>
      <template #default>Помощь</template>
    </base-header>
    <base-container>
      <base-button :href="{ name: 'Feedback' }" class="btn--primary btn--block"
        >Связаться с нами</base-button
      >
      <h2 class="help-page__subtitle">Справка</h2>
      <p>Метод 365 основан на ежедневном откладывании денег.</p>
      <p>
        Это видоизмененный метод Гаусса, который мы превратили в онлайн
        игру-копилку.
      </p>
      <p>Суть игры - откладывать каждый день сумму, от 1 до 365.</p>
      <p>
        Вы можете начать с 1 рубля и каждый день увеличивать на рубль, или же
        откладывать каждый день разную сумму исходя из возможности (сдачи,
        мелочи, свободных денег)
      </p>
      <p>
        Для наглядности вам больше не нужно распечатывать лист бумаги, теперь
        можно просто установить приложение, которое будет напоминать вам о
        необходимости пополнить "копилку"
      </p>
      <p>
        Главное - относится к этому действу как к игре. Спокойно, не торопиться,
        но и не бросать на полпути.
      </p>
      <p>
        Игра по накоплению будет закончена, когда все числа от 1 до 365 будут
        вычеркнуты. После этого можно смело открывать копилку или банковский
        счет и подсчитывать доходы.
      </p>
      <p>Для удобства мы сделали возможность создания целей.</p>
    </base-container>
  </base-page>
</template>

<script>
import BasePage from "@/components/base/BasePage";
import BaseContainer from "@/components/base/BaseContainer";
import BaseHeader from "@/components/base/BaseHeader";
import BaseButton from "@/components/base/BaseButton";
export default {
  name: "HelpPage",
  components: { BaseButton, BaseHeader, BaseContainer, BasePage },
  mounted() {
    document.title = "Помощь";
  },
};
</script>

<style lang="scss" scoped>
.help-page {
  &__subtitle {
    font-size: 22px;
    font-weight: 600;
    color: #223263;
    margin-top: 16px;
  }
}
.dark {
  .help-page {
    color: $grey;
    &__subtitle {
      color: $blueGray;
    }
  }
}
</style>
