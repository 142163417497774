<template>
  <base-page>
    <base-loader :loading="loading" />
    <base-header
      @click.native="$router.push({ name: 'Login' })"
      :no-scroll="true"
    >
      <template #back>
        <svg-icon name="chevron-left" />
      </template>
      <template #default>Назад</template>
    </base-header>
    <base-container>
      <p>
        Сейчас вы будете перенаправлены на страницу авторизации Вконтакте, если
        этого не случилось нажмите на кнопку ниже
      </p>
      <base-button
        :loading="loading"
        @click.native="vkAuth"
        class="btn--primary-dark btn--block"
        >Войти через Вконтакте</base-button
      >
    </base-container>
  </base-page>
</template>
<script>
import { VKAuth } from "capacitor-plugin-vk-auth";
import axios from "axios";
import store from "@/store";
import router from "@/router";
import BasePage from "@/components/base/BasePage";
import BaseContainer from "@/components/base/BaseContainer";
import BaseButton from "@/components/base/BaseButton";
import BaseHeader from "@/components/base/BaseHeader";
import SvgIcon from "@/components/SvgIcon";
import BaseLoader from "@/components/base/BaseLoader";
export default {
  name: "LoginPageVK",
  components: {
    BaseLoader,
    SvgIcon,
    BaseHeader,
    BaseButton,
    BaseContainer,
    BasePage,
  },
  data() {
    return {
      loading: false,
    };
  },
  created() {
    this.vkAuth();
  },
  methods: {
    vkAuth() {
      this.loading = true;
      VKAuth.initWithId({ id: "7873064" });
      VKAuth.auth({ scope: ["offline"] });
      const { remove } = VKAuth.addListener("vkAuthFinished", (info) => {
        if (info.result === true) {
          axios
            .post("/login-vk", { vkId: info.userId })
            .then((loginResponse) => {
              if (
                loginResponse.data.result === "success" &&
                loginResponse.data.token
              ) {
                store.commit("LogIn", loginResponse.data.token);
                axios.get("/user").then((r) => {
                  store.commit("SetUser", r.data);
                  this.loading = false;
                });
                if (loginResponse.data.registered === 0) {
                  router.push("/registration");
                } else {
                  router.push("/home");
                }
                remove();
              }
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.dark p {
  color: #fff;
}
</style>
