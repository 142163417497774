var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-page',[_c('base-header',{attrs:{"no-scroll":true},nativeOn:{"click":function($event){return _vm.$router.go(-1)}},scopedSlots:_vm._u([{key:"back",fn:function(){return [_c('svg-icon',{attrs:{"name":"chevron-left"}})]},proxy:true},(_vm.editForm)?{key:"default",fn:function(){return [_vm._v("Редактирование копилки")]},proxy:true}:{key:"default",fn:function(){return [_vm._v(" Добавление копилки ")]},proxy:true}],null,true)}),_c('base-container',[_c('form',{staticClass:"storage-form",attrs:{"action":"#"},on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('base-input',{attrs:{"error":{
          status: _vm.$v.name.$error,
          text: 'Нельзя оставлять пустым',
        },"label":"На что собираетесь копить?","placeholder":"Например На отпуск"},model:{value:(_vm.$v.name.$model),callback:function ($$v) {_vm.$set(_vm.$v.name, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.name.$model"}}),(!_vm.isPremium)?_c('premium-window',{attrs:{"title":"Расширенный режим доступен с Premium всего за "}}):_vm._e(),_c('div',{staticClass:"storage-form__item"},[_c('div',{staticClass:"storage-form__text",on:{"click":function($event){return _vm.$refs.infoSheet.open()}}},[_vm._v(" Расширенный режим ")]),_c('base-toggle-ios',{attrs:{"id":1,"true-value":"advanced","false-value":"simple"},model:{value:(_vm.mode),callback:function ($$v) {_vm.mode=$$v},expression:"mode"}})],1),(_vm.mode === 'advanced')?[_c('base-input',{attrs:{"error":{
            status: _vm.$v.startFrom.$error,
            text: 'Нельзя оставлять пустым',
          },"input-type":"number","disabled":_vm.disableMode,"label":"Копилка начинается с этого дня:","placeholder":"Например 1"},model:{value:(_vm.$v.startFrom.$model),callback:function ($$v) {_vm.$set(_vm.$v.startFrom, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.startFrom.$model"}}),_c('base-input',{attrs:{"error":{
            status: _vm.$v.step.$error,
            text: 'Нельзя оставлять пустым',
          },"input-type":"number","disabled":_vm.disableMode,"label":"Шаг копилки:","placeholder":"Например 1"},model:{value:(_vm.$v.step.$model),callback:function ($$v) {_vm.$set(_vm.$v.step, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.step.$model"}}),_c('base-input',{attrs:{"error":{
            status: _vm.$v.endOn.$error,
            text: 'Нельзя оставлять пустым',
          },"input-type":"number","disabled":_vm.disableMode,"label":"Количество дней для накопления:","placeholder":"Например 365"},model:{value:(_vm.$v.endOn.$model),callback:function ($$v) {_vm.$set(_vm.$v.endOn, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.endOn.$model"}})]:_vm._e(),_c('h2',{staticClass:"storage-form__label"},[_vm._v("Иконка цели")]),_c('div',{staticClass:"storage-form__overflow"},[_c('div',{staticClass:"storage-form__icons"},_vm._l((_vm.icons),function(icon){return _c('div',{key:("icon-" + icon),staticClass:"storage-form__icon",class:{ active: _vm.selectedIcon === icon },on:{"click":function($event){_vm.selectedIcon = icon}}},[_c('svg-icon',{attrs:{"name":icon}})],1)}),0)]),_c('h2',{staticClass:"storage-form__label"},[_vm._v("Цвет цели")]),_c('div',{staticClass:"storage-form__colors"},_vm._l((_vm.colors),function(color){return _c('div',{key:("color-" + color),staticClass:"storage-form__color",style:({
            'background-color': color,
          }),on:{"click":function($event){_vm.selectedColor = color}}},[(color === _vm.selectedColor)?_c('svg-icon',{attrs:{"name":"check"}}):_vm._e()],1)}),0),_c('div',{staticClass:"storage-form__btns"},[_c('base-button',{staticClass:"btn--primary btn--block",attrs:{"loading":_vm.loading}},[_vm._v("Сохранить")])],1)],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }