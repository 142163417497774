<template>
  <base-page has-menu class="feedback-page">
    <base-header
      @click.native="$router.push({ name: 'Help' })"
      :no-scroll="true"
    >
      <template #back>
        <svg-icon name="chevron-left" />
      </template>
      <template #default>Напишите нам</template>
    </base-header>
    <base-container>
      <template v-if="messages && messages.length > 0">
        <base-message
          v-for="message in messages"
          :key="`message-${message.id}`"
          :user="message.user_id === '52' ? 'Админ' : 'Вы'"
          :text="message.message"
        />
      </template>
    </base-container>
    <form class="feedback-page__form" @submit.prevent="submit" action="#">
      <base-input
        v-model="$v.message.$model"
        placeholder="Ваше сообщение"
        :error="{
          status: $v.message.$error,
          text: '',
        }"
      />
      <base-button class="btn--primary btn--block">
        <svg-icon name="send" />
      </base-button>
    </form>
  </base-page>
</template>

<script>
import BasePage from "@/components/base/BasePage";
import BaseContainer from "@/components/base/BaseContainer";
import BaseHeader from "@/components/base/BaseHeader";
import BaseButton from "@/components/base/BaseButton";
import SvgIcon from "@/components/SvgIcon";
import { required } from "vuelidate/lib/validators";
import axios from "axios";
import BaseMessage from "@/components/base/BaseMessage";
import BaseInput from "@/components/base/inputs/BaseInput";
export default {
  name: "FeedBackPage",
  components: {
    BaseInput,
    BaseMessage,
    SvgIcon,
    BaseButton,
    BaseHeader,
    BaseContainer,
    BasePage,
  },
  data() {
    return {
      message: null,
      loading: false,
      messages: [],
      intervalId: null,
    };
  },
  validations: {
    message: {
      required,
    },
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = true;
        axios
          .post("/messages/insert", { message: this.message })
          .then((r) => {
            this.loading = false;
            if (r.data.result === "success") {
              this.message = null;
              this.getMessages();
            }
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    getMessages() {
      axios.get("/messages/get").then((r) => {
        this.messages = r.data.messages;
      });
    },
  },
  mounted() {
    document.title = "Напишите нам";
  },
  created() {
    this.getMessages();
    this.intervalId = setInterval(() => {
      this.getMessages();
    }, 2000);
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
};
</script>

<style lang="scss">
.ios-stripe .feedback-page__form {
  padding-bottom: 16px;
  height: auto;
}
.feedback-page {
  &__subtitle {
    font-size: 22px;
    font-weight: 600;
    color: #223263;
    margin-top: 16px;
    margin-bottom: 16px;
  }
  &__form {
    display: flex;
    position: fixed;
    bottom: 0;
    width: 100vw;
    padding: 8px 8px 8px 8px;
    background: #fff;

    .input-group {
      margin-bottom: 0;
      width: calc(100% - 64px);
    }
    .input-group__error {
      display: none;
    }

    .input-group--error .input-group__input {
      border: 1px solid #858c94;
    }

    .btn {
      padding: 0;
      min-width: auto;
      min-height: auto;
      width: 56px;
      margin-left: 8px;
    }
  }
}
.dark {
  .help-page {
    color: $grey;
    &__subtitle {
      color: $blueGray;
    }
  }
}
</style>
