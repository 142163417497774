import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import HomePage from "@/views/HomePage";
import LoginPage from "@/views/LoginPage";
import RegistrationPage from "@/views/RegistrationPage";
import ProfilePage from "@/views/ProfilePage";
import ProfileEditPage from "@/views/ProfileEditPage";
import FormStoragePage from "@/views/FormStoragePage";
import SingleStoragePage from "@/views/SingleStoragePage";
import SettingsPage from "@/views/SettingsPage";
import HelpPage from "@/views/HelpPage";
import ConfirmEmailPage from "@/views/ConfirmEmailPage";
import ProfilePhonePage from "@/views/ProfilePhonePage";
import ProfileEmailPage from "@/views/ProfileEmailPage";
import ProfilePasswordPage from "@/views/ProfilePasswordPage";
import FeedBackPage from "@/views/FeedBackPage";
import LoginPageEmail from "@/views/LoginPageEmail";
import LoginPagePhone from "@/views/LoginPagePhone";
import PrivacyPage from "@/views/PrivacyPage";
import LoginPageVK from "@/views/LoginPageVK";
import LoginPageGoogle from "@/views/LoginPageGoogle";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "Home",
    component: HomePage,
    meta: { requiresAuth: true },
  },
  {
    path: "/home/add",
    name: "FormStorageAdd",
    component: FormStoragePage,
    meta: { requiresAuth: true, noMenu: true },
  },
  {
    path: "/home/:id/edit",
    name: "FormStorageEdit",
    component: FormStoragePage,
    meta: { requiresAuth: true, edit: true, noMenu: true },
  },
  {
    path: "/home/:id",
    name: "SingleStorage",
    component: SingleStoragePage,
    meta: { requiresAuth: true, noMenu: true },
  },
  {
    path: "/login",
    name: "Login",
    component: LoginPage,
    meta: { guest: true, noMenu: true },
  },
  {
    path: "/login/email",
    name: "LoginEmail",
    component: LoginPageEmail,
    meta: { guest: true, noMenu: true },
  },
  {
    path: "/login/phone",
    name: "LoginPhone",
    component: LoginPagePhone,
    meta: { guest: true, noMenu: true },
  },
  {
    path: "/login/vk",
    name: "LoginVk",
    component: LoginPageVK,
    meta: { guest: true, noMenu: true },
  },
  {
    path: "/login/google",
    name: "LoginGoogle",
    component: LoginPageGoogle,
    meta: { guest: true, noMenu: true },
  },
  {
    path: "/registration",
    name: "Registration",
    component: RegistrationPage,
    meta: { requiresAuth: true, noMenu: true },
  },
  {
    path: "/profile",
    name: "Profile",
    component: ProfilePage,
    meta: { requiresAuth: true },
  },
  {
    path: "/profile/edit",
    name: "ProfileEdit",
    component: ProfileEditPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/profile/phone",
    name: "ProfilePhone",
    component: ProfilePhonePage,
    meta: { requiresAuth: true },
  },
  {
    path: "/profile/email",
    name: "ProfileEmail",
    component: ProfileEmailPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/profile/password",
    name: "ProfilePassword",
    component: ProfilePasswordPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/settings",
    name: "Settings",
    component: SettingsPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/help",
    name: "Help",
    component: HelpPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/help/feedback",
    name: "Feedback",
    component: FeedBackPage,
    meta: { requiresAuth: true, noMenu: true },
  },
  {
    path: "/activate/:hash",
    name: "Activate",
    component: ConfirmEmailPage,
    meta: { guest: true, noMenu: true },
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: PrivacyPage,
    meta: { guest: true, noMenu: true },
  },
];

const router = new VueRouter({
  routes,
  mode: "hash",
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.guest)) {
    if (store.getters.isAuthenticated) {
      next("/home");
      return;
    }
    next();
  } else {
    next();
  }
});

export default router;
