var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-page',[_c('base-header',{attrs:{"no-scroll":true},nativeOn:{"click":function($event){return _vm.$router.push({
        name: 'Profile',
      })}},scopedSlots:_vm._u([{key:"back",fn:function(){return [_c('svg-icon',{attrs:{"name":"chevron-left"}})]},proxy:true},{key:"default",fn:function(){return [_vm._v("Изменение номера телефона")]},proxy:true}])}),_c('base-container',[_c('form',{attrs:{"action":"#"},on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[(_vm.user.phone)?_c('base-phone-input',{attrs:{"block":true,"disabled":true,"value":_vm.user.phone,"label":"Ваш текущий номер телефона"}}):_vm._e(),_c('base-phone-input',{attrs:{"block":true,"label":"Введите новый номер телефона","error":{
          status: _vm.$v.newPhone.$error,
          text: 'Введите номер телефона',
        }},on:{"change":function($event){_vm.smsWasSended = false}},model:{value:(_vm.$v.newPhone.$model),callback:function ($$v) {_vm.$set(_vm.$v.newPhone, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.newPhone.$model"}}),_c('base-input',{directives:[{name:"show",rawName:"v-show",value:(_vm.smsWasSended),expression:"smsWasSended"}],attrs:{"label":"Код из смс","error":{
          status: _vm.$v.smsCode.$error,
          text: 'Введите код из 4 цифр',
        }},model:{value:(_vm.$v.smsCode.$model),callback:function ($$v) {_vm.$set(_vm.$v.smsCode, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.smsCode.$model"}}),_c('base-button',{staticClass:"btn--primary btn--block",attrs:{"loading":_vm.loading,"button-type":"submit"}},[(_vm.smsWasSended)?[_vm._v("Сохранить")]:[_vm._v("Получить СМС")]],2)],1),(_vm.serverError)?_c('base-alert',{staticStyle:{"margin-top":"32px"},attrs:{"type":"danger","center":true}},[_vm._v(" "+_vm._s(_vm.serverError)+" ")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }