<template>
  <div v-if="!isPremium" class="premium-wrapper">
    <div class="premium" @click="$refs.infoSheet.open()">
      <svg-icon name="crown" />
      <div>
        {{ title }}
        <b>{{ premium.price }}</b>
      </div>
    </div>
    <vue-bottom-sheet :rounded="isIos" ref="infoSheet">
      <div class="premium__info">
        <svg-icon name="crown" />
        <p>
          Premium навсегда отключает рекламу в приложении, даёт Вам доступ к
          расширенному режиму создания копилок в котором вы можете менять
          количество дней накопления, шаг накопления и прочее
        </p>
        <base-button
          @click.native="buyPremium"
          button-type="button"
          class="btn--primary btn--block"
          >Купить премиум за <b>{{ premium.price }}</b></base-button
        >
        <base-button
          @click.native="$refs.infoSheet.close()"
          button-type="button"
          class="btn--danger btn--block"
          >Закрыть</base-button
        >
      </div>
    </vue-bottom-sheet>
  </div>
</template>

<script>
import SvgIcon from "@/components/SvgIcon";
import { convertPrice } from "@/helpers";
import VueBottomSheet from "@webzlodimir/vue-bottom-sheet";
import BaseButton from "@/components/base/BaseButton";
import { InAppPurchase2 } from "@ionic-native/in-app-purchase-2";
import { mapGetters, mapState } from "vuex";

export default {
  name: "PremiumWindow",
  components: { BaseButton, SvgIcon, VueBottomSheet },
  data() {
    return {
      platform: null,
    };
  },
  props: {
    title: String,
  },
  methods: {
    convertPrice,
    buyPremium() {
      this.$refs.infoSheet.close();
      InAppPurchase2.order("premium");
    },
  },
  computed: {
    ...mapGetters(["isPremium", "isIos"]),
    ...mapState(["premium"]),
  },
};
</script>

<style lang="scss">
.premium {
  display: grid;
  grid-template-columns: 60px auto;
  padding: 8px 16px;
  -moz-column-gap: 16px;
  column-gap: 16px;
  background: #03a9f4;
  align-items: center;
  border-radius: 16px;
  margin-bottom: 16px;
  margin-top: 16px;
  color: #fff;

  &__info {
    padding: 16px;
    text-align: center;

    .svg-icon {
      width: 80px;
      height: 80px;
    }

    .btn + .btn {
      margin-top: 8px;
    }
  }

  .svg-icon {
    width: 60px;
    height: 60px;
  }
}
</style>
