<template>
  <div
    class="input-group"
    :class="{
      'input-group--error': error.status,
      'input-group--block': block,
      'input-group--focused': focused,
      'input-group--compact': compact,
    }"
  >
    <div class="input-group__wrapper">
      <label v-if="label && label.length > 0" class="input-group__label"
        >{{ label }} <span v-if="required">*</span></label
      >
      <input
        ref="basePhoneInput"
        v-imask="mask"
        class="input-group__input"
        type="tel"
        :placeholder="placeholder"
        :required="required ? 'required' : false"
        :value="value"
        :disabled="disabled"
        @focus="focused = true"
        @blur="focused = false"
        @accept="onAccept"
        @complete="onComplete"
      />
    </div>
    <div v-if="error.status" class="input-group__error">
      {{ error.text }}
    </div>
    <p v-if="description" class="input-group__input-description">
      {{ description }}
    </p>
  </div>
</template>
<script>
import { IMaskDirective } from "vue-imask";
import inputMixins from "@/components/base/inputs/inputMixins";

export default {
  name: "BasePhoneInput",
  mixins: [inputMixins],
  directives: {
    imask: IMaskDirective,
  },
  data() {
    return {
      mask: {
        mask: [
          {
            mask: "+7 (000) 000-00-00",
            startsWith: "+7",
            country: "Russia",
          },
          {
            mask: "+7 (000) 000-00-00",
            startsWith: "7",
            country: "Russia",
          },
          {
            mask: "0 (000) 000-00-00",
            startsWith: "8",
            country: "Russia",
          },
          {
            mask: "+7 (000) 000-00-00",
            startsWith: "",
            country: "unknown",
          },
        ],
        dispatch(appended, dynamicMasked) {
          const number = (dynamicMasked.value + appended).replace(/\D/g, "");
          return dynamicMasked.compiledMasks.find(function (m) {
            return number.indexOf(m.startsWith) === 0;
          });
        },
      },
    };
  },
  methods: {
    onAccept(e) {
      this.$emit("input", e.detail.value);
    },
    onComplete(e) {
      this.$emit("input", e.detail.value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "inputStyles";
</style>
