function startOneSinglaSubscription(externalUserId) {
  //START ONESIGNAL CODE
  //Remove this method to stop OneSignal Debugging
  // window.plugins.OneSignal.setLogLevel({ logLevel: 6, visualLevel: 0 });

  var notificationOpenedCallback = function (jsonData) {
    console.log("notificationOpenedCallback: " + JSON.stringify(jsonData));
  };
  // Set your iOS Settings
  var iosSettings = {};
  iosSettings["kOSSettingsKeyAutoPrompt"] = false;
  iosSettings["kOSSettingsKeyInAppLaunchURL"] = false;

  window.plugins.OneSignal.startInit("c2f4aed0-fa64-4466-aef4-a0607aa2c12c")
    .handleNotificationOpened(notificationOpenedCallback)
    .iOSSettings(iosSettings)
    .inFocusDisplaying(
      window.plugins.OneSignal.OSInFocusDisplayOption.Notification
    )
    .endInit();
  window.plugins.OneSignal.setExternalUserId(externalUserId.toString());

  // The promptForPushNotificationsWithUserResponse function will show the iOS push notification prompt. We recommend removing the following code and instead using an In-App Message to prompt for notification permission (See step 6)
  window.plugins.OneSignal.promptForPushNotificationsWithUserResponse(function (
    accepted
  ) {
    console.log("User accepted notifications: " + accepted);
  });
  //END ONESIGNAL CODE
}

function convertPrice(price, isRound = false, currency = "RUB") {
  price = isRound ? Math.round(price) : price;
  if (price) {
    return price.toLocaleString("ru-RU", {
      style: "currency",
      currency,
      minimumFractionDigits: 0,
    });
  } else {
    return `0 ${currency === "RUB" ? "₽" : "$"}`;
  }
}

export { startOneSinglaSubscription, convertPrice };
