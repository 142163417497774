<template>
  <div class="toggle-wrapper">
    <input
      type="checkbox"
      name="toggle1"
      class="mobile-toggle"
      :class="{ 'mobile-toggle--android': isAndroid }"
      :id="`toggle-${id}`"
      :checked="isChecked"
      :value="value"
      @change="updateInput"
    />
    <label :for="`toggle-${id}`"></label>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "BaseToggleIos",
  model: {
    prop: "modelValue",
    event: "change",
  },
  props: {
    id: {
      type: Number,
      default: null,
      required: true,
    },
    value: { type: String, default: "" },
    modelValue: { default: "" },
    trueValue: { type: [Boolean, Number, String], default: true },
    falseValue: { type: [Boolean, Number, String], default: false },
  },
  computed: {
    ...mapGetters(["isAndroid"]),
    isChecked() {
      if (Array.isArray(this.modelValue)) {
        return this.modelValue.includes(this.value);
      }
      // Note that `true-value` and `false-value` are camelCase in the JS
      return this.modelValue === this.trueValue;
    },
  },
  methods: {
    updateInput(event) {
      const isChecked = event.target.checked;
      if (Array.isArray(this.modelValue)) {
        const newValue = [...this.modelValue];
        if (isChecked) {
          newValue.push(this.value);
        } else {
          newValue.splice(newValue.indexOf(this.value), 1);
        }
        this.$emit("change", newValue);
      } else {
        this.$emit("change", isChecked ? this.trueValue : this.falseValue);
      }
    },
  },
};
</script>

<style lang="scss">
@mixin inactive-mixin {
  content: "";
  position: absolute;
  display: block;
}

@mixin before-animation {
  transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
}

@mixin after-animation {
  box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 0px 0 hsla(0, 0%, 0%, 0.04),
    0 4px 9px hsla(0, 0%, 0%, 0.13), 0 3px 3px hsla(0, 0%, 0%, 0.05);
  transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
}

// Mobile Toggle Switch
.toggle-wrapper {
  input {
    &.mobile-toggle {
      opacity: 0; // hides checkbox
      position: absolute;
      & + label {
        position: relative;
        display: inline-block;
        user-select: none;
        transition: 0.4s ease;
        height: 30px;
        width: 50px;
        border: 1px solid #e4e4e4;
        border-radius: 60px;
        &:before {
          @include inactive-mixin;
          @include before-animation;
          height: 30px;
          width: 51px;
          top: 0;
          left: 0;
          border-radius: 30px;
        }
        &:after {
          @include inactive-mixin;
          @include after-animation;
          background: $background;
          height: 28px;
          width: 28px;
          top: 1px;
          left: 0;
          border-radius: 60px;
        }
      }
      // When Active
      &:checked {
        & + label:before {
          background: $green; // Active Color
          transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
        }
        & + label:after {
          left: 54px - 30px;
        }
      }
      &--android {
        &:checked {
          & + label:before {
            background: $primary; // Active Color
          }
        }
      }
    }
  }
}
</style>
