<template>
  <div class="page" :class="{ 'page--has-menu': hasMenu }">
    <slot />
  </div>
</template>

<script>
export default {
  name: "BasePage",
  props: {
    hasMenu: Boolean,
  },
};
</script>

<style lang="scss">
.page {
  min-height: 100vh;
  .container {
    min-height: 100%;
    width: 100%;
  }
  &--has-menu {
    padding-bottom: 76px;
  }
}
</style>
