var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-page',{staticClass:"login-page",attrs:{"has-header":true}},[_c('base-header',{attrs:{"no-scroll":true},nativeOn:{"click":function($event){return _vm.$router.push({ name: 'Login' })}},scopedSlots:_vm._u([{key:"back",fn:function(){return [_c('svg-icon',{attrs:{"name":"chevron-left"}})]},proxy:true},{key:"default",fn:function(){return [_vm._v("Назад")]},proxy:true}])}),_c('base-container',[_c('div',{staticClass:"login-page__wrapper"},[_c('div',{staticClass:"login-page__hello"},[_c('img',{staticClass:"login-page__logo",attrs:{"src":require("@/assets/img/logo.svg"),"alt":"Копилка 365 - Накопить за год легко"}}),_c('h1',{staticClass:"login-page__title"},[_vm._v("Копилка 365 - Накопить за год легко")])]),_c('form',{staticClass:"login-page__form",attrs:{"method":"POST"},on:{"submit":function($event){$event.preventDefault();return _vm.submitEmailForm($event)}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.emailSubmited),expression:"!emailSubmited"}]},[_c('base-input',{attrs:{"label":"E-mail","compact":true,"error":{
              status: _vm.$v.email.$error,
              text: 'Введите e-mail',
            },"block":true,"placeholder":"Введите e-mail"},model:{value:(_vm.$v.email.$model),callback:function ($$v) {_vm.$set(_vm.$v.email, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.email.$model"}}),_c('base-input',{directives:[{name:"show",rawName:"v-show",value:(_vm.emailLoginType === 'login'),expression:"emailLoginType === 'login'"}],attrs:{"label":"Пароль","compact":true,"error":{
              status: _vm.$v.password.$error,
              text: 'Введите пароль',
            },"block":true,"input-type":"password","placeholder":"Введите пароль"},model:{value:(_vm.$v.password.$model),callback:function ($$v) {_vm.$set(_vm.$v.password, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.password.$model"}}),_c('base-button',{staticClass:"btn--primary btn--block",attrs:{"loading":_vm.loading,"button-type":"submit"}},[(_vm.emailLoginType === 'login')?[_vm._v(" Войти ")]:(_vm.emailLoginType === 'recover')?[_vm._v(" Сбросить пароль ")]:[_vm._v(" Зарегистрироваться ")]],2),_c('div',{staticClass:"login-page__btns",class:{ 'login-page__btns--full': _vm.emailLoginType !== 'login' }},[_c('base-button',{staticClass:"btn--secondary btn--block",attrs:{"button-type":"button"},nativeOn:{"click":function($event){_vm.emailLoginType =
                  _vm.emailLoginType === 'login' ? 'register' : 'login';
                _vm.serverError = null;}}},[(_vm.emailLoginType === 'login')?[_vm._v(" Нет аккаунта ")]:[_vm._v(" Есть аккаунт ")]],2),(_vm.emailLoginType === 'login')?_c('base-button',{staticClass:"btn--secondary btn--block",attrs:{"button-type":"button"},nativeOn:{"click":function($event){_vm.emailLoginType = 'recover'}}},[_vm._v(" Забыл пароль ")]):_vm._e()],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.emailSubmited),expression:"emailSubmited"}]},[(!_vm.emailRecovered)?_c('base-alert',{attrs:{"type":"primary","center":true}},[_vm._v(" Вы успешно зарегистрированы, вам отправлено письмо с подтверждением электронного ящика ")]):_vm._e(),(_vm.emailRecovered)?_c('base-alert',{attrs:{"type":"primary","center":true}},[_vm._v(" Ваш пароль успешно сброшен и отправлен на ваш e-mail ")]):_vm._e(),_c('base-button',{staticClass:"btn--primary btn--block",attrs:{"button-type":"button"},nativeOn:{"click":function($event){_vm.emailSubmited = false;
              _vm.emailLoginType = 'login';}}},[_vm._v("Перейти на форму входа")])],1),_c('router-link',{staticClass:"login-page__link",attrs:{"to":{ name: 'Login' },"href":"#"}},[_vm._v("Другие варианты входа")]),_c('base-alert',{attrs:{"center":true,"type":"danger"}},[_vm._v(_vm._s(_vm.serverError))])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }