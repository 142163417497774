<template>
  <base-page class="activate-page">
    <base-container>
      <div class="activate-page__text">
        <base-alert type="primary">
          Вы успешно подтвердили свой электронный адрес и мы отправили на него
          пароль от вашего аккаунта, теперь Вы можете войти в приложение
        </base-alert>
      </div>
    </base-container>
  </base-page>
</template>

<script>
import BasePage from "@/components/base/BasePage";
import BaseContainer from "@/components/base/BaseContainer";
import BaseAlert from "@/components/base/BaseAlert";
import axios from "axios";
export default {
  name: "ConfirmEmailPage",
  components: { BaseAlert, BaseContainer, BasePage },
  data() {
    return {
      confirmed: true,
    };
  },
  created() {
    axios.get(`/user/activate/${this.$route.params.hash}`);
  },
  mounted() {
    document.title = "Подтверждение e-mail";
  },
};
</script>

<style lang="scss">
.activate-page {
  padding-top: 16px;
  padding-bottom: 16px;
}

@media (min-width: 1220px) {
  .activate-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__text {
      width: 500px;
      margin: 0 auto;
      text-align: center;
    }
  }
}
</style>
