<template>
  <base-page :has-header="false" class="login-page variants">
    <base-container>
      <div class="login-page__hello">
        <img
          src="~@/assets/img/logo.svg"
          class="login-page__logo"
          alt="Копилка 365 - Накопить за год легко"
        />
        <h1 class="login-page__title">Копилка 365 - Накопить за год легко</h1>
      </div>
      <div class="login-variants__btns">
        <base-button
          v-if="!isWeb"
          :href="{ name: 'LoginVk' }"
          class="btn--primary-dark btn--block"
        >
          Войти с помощью Вконтакте
        </base-button>
        <base-button
          v-if="!isWeb"
          :href="{ name: 'LoginGoogle' }"
          class="btn--danger btn--block"
        >
          Войти с помощью Google
        </base-button>
        <base-button
          :href="{ name: 'LoginEmail' }"
          class="btn--primary btn--block"
        >
          Войти с помощью E-mail
        </base-button>
        <base-button
          :href="{ name: 'LoginPhone' }"
          class="btn--primary btn--block"
        >
          Вход по номеру телефона
        </base-button>
      </div>
    </base-container>
  </base-page>
</template>

<script>
import BaseContainer from "@/components/base/BaseContainer";
import BaseButton from "@/components/base/BaseButton";
import BasePage from "@/components/base/BasePage";
import { mapGetters } from "vuex";

export default {
  name: "LoginPage",
  components: {
    BaseButton,
    BaseContainer,
    BasePage,
  },
  mounted() {
    document.title = "Авторизация";
  },
  computed: {
    ...mapGetters(["isWeb"]),
  },
};
</script>

<style lang="scss">
.login-page {
  &.variants {
    .container {
      height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
  &__hello {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 32px;
  }
  &__logo {
    width: 110px;
    height: 110px;
  }
  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 150%;
    text-align: center;
    color: $secondary;
    margin-top: 16px;
    margin-bottom: 32px;
  }
  &__desc {
    font-size: 16px;
    line-height: 1;
    text-align: center;
    color: $grey;
    margin-top: 8px;
    margin-bottom: 16px;
  }
}
.dark {
  .login-page {
    &__title {
      color: $blueGray;
    }
  }
}
@media (min-width: 1220px) {
  .login-page {
    &__wrapper {
      max-width: 400px;
      margin: 0 auto;
    }
  }
}
.login-variants {
  height: 100%;
  width: 100%;
  &__btns {
    padding-top: 16px;
    display: grid;
    gap: 16px;
  }
}
@media (min-width: 1200px) {
  .login-variants {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &__btns {
      width: 300px;
      margin: 0 auto;
    }
  }
}
</style>
