<template>
  <base-page>
    <base-header @click.native="$router.go(-1)" :no-scroll="true">
      <template #back>
        <svg-icon name="chevron-left" />
      </template>
      <template v-if="editForm" #default>Редактирование копилки</template>
      <template v-else #default> Добавление копилки </template>
    </base-header>
    <base-container>
      <form @submit.prevent="submit" class="storage-form" action="#">
        <base-input
          :error="{
            status: $v.name.$error,
            text: 'Нельзя оставлять пустым',
          }"
          v-model.trim="$v.name.$model"
          label="На что собираетесь копить?"
          placeholder="Например На отпуск"
        />
        <premium-window
          title="Расширенный режим доступен с Premium всего за "
          v-if="!isPremium"
        />
        <div class="storage-form__item">
          <div class="storage-form__text" @click="$refs.infoSheet.open()">
            Расширенный режим
          </div>
          <base-toggle-ios
            v-model="mode"
            :id="1"
            true-value="advanced"
            false-value="simple"
          />
        </div>
        <template v-if="mode === 'advanced'">
          <base-input
            :error="{
              status: $v.startFrom.$error,
              text: 'Нельзя оставлять пустым',
            }"
            input-type="number"
            v-model.trim="$v.startFrom.$model"
            :disabled="disableMode"
            label="Копилка начинается с этого дня:"
            placeholder="Например 1"
          />
          <base-input
            :error="{
              status: $v.step.$error,
              text: 'Нельзя оставлять пустым',
            }"
            input-type="number"
            v-model.trim="$v.step.$model"
            :disabled="disableMode"
            label="Шаг копилки:"
            placeholder="Например 1"
          />
          <base-input
            :error="{
              status: $v.endOn.$error,
              text: 'Нельзя оставлять пустым',
            }"
            input-type="number"
            v-model.trim="$v.endOn.$model"
            :disabled="disableMode"
            label="Количество дней для накопления:"
            placeholder="Например 365"
          />
        </template>
        <h2 class="storage-form__label">Иконка цели</h2>
        <div class="storage-form__overflow">
          <div class="storage-form__icons">
            <div
              @click="selectedIcon = icon"
              :class="{ active: selectedIcon === icon }"
              :key="`icon-${icon}`"
              v-for="icon in icons"
              class="storage-form__icon"
            >
              <svg-icon :name="icon" />
            </div>
          </div>
        </div>
        <h2 class="storage-form__label">Цвет цели</h2>
        <div class="storage-form__colors">
          <div
            v-for="color in colors"
            :key="`color-${color}`"
            class="storage-form__color"
            @click="selectedColor = color"
            :style="{
              'background-color': color,
            }"
          >
            <svg-icon v-if="color === selectedColor" name="check" />
          </div>
        </div>
        <div class="storage-form__btns">
          <base-button :loading="loading" class="btn--primary btn--block"
            >Сохранить</base-button
          >
        </div>
      </form>
    </base-container>
  </base-page>
</template>

<script>
import BasePage from "@/components/base/BasePage";
import BaseHeader from "@/components/base/BaseHeader";
import SvgIcon from "@/components/SvgIcon";
import BaseContainer from "@/components/base/BaseContainer";
import BaseInput from "@/components/base/inputs/BaseInput";
import BaseButton from "@/components/base/BaseButton";
import PremiumWindow from "@/components/PremiumWindow";
import BaseToggleIos from "@/components/base/BaseToggleIos";

import { required, minValue } from "vuelidate/lib/validators";
import axios from "axios";
import { mapGetters } from "vuex";
import { InterstitialAd } from "@admob-plus/capacitor";

export default {
  name: "FormStoragePage",
  components: {
    PremiumWindow,
    BaseToggleIos,
    BaseButton,
    BaseInput,
    BaseContainer,
    SvgIcon,
    BaseHeader,
    BasePage,
  },
  data() {
    return {
      name: null,
      startFrom: 1,
      endOn: 365,
      step: 1,
      selectedIcon: "accessible",
      selectedColor: "#f44336",
      loading: false,
      mode: "simple",
      colors: [
        "#f44336",
        "#E91E63",
        "#9C27B0",
        "#673AB7",
        "#3F51B5",
        "#2196F3",
        "#03A9F4",
        "#00BCD4",
        "#009688",
        "#4CAF50",
        "#8BC34A",
        "#FF9800",
        "#FF5722",
        "#795548",
        "#607D8B",
        "#212121",
      ],
      icons: [
        "accessible",
        "account_balance",
        "account_circle",
        "add_a_photo",
        "agriculture",
        "airplanemode_active",
        "airport_shuttle",
        "alarm",
        "audiotrack",
        "baby_changing_station",
        "bathtub",
        "beach_access",
        "bike_scooter",
        "bookmarks",
        "brightness_2",
        "brightness_5",
        "build",
        "business",
        "business_center",
        "cake",
        "call",
        "child_friendly",
        "cleaning_services",
        "cloud",
        "color_lens",
        "comment",
        "commute",
        "computer",
        "contactless",
        "corporate_fare",
        "delete",
        "desktop_mac",
        "devices",
        "directions_bike",
        "directions_boat",
        "directions_bus",
        "directions_car",
        "directions_run",
        "domain",
        "email",
        "error_outline",
        "explore",
        "family_restroom",
        "fastfood",
        "favorite",
        "favorite_border",
        "fingerprint",
        "flight",
        "folder_open",
        "free_breakfast",
        "gps_fixed",
        "grass",
        "handyman",
        "headset",
        "home",
        "home_repair_service",
        "home_work",
        "hotel",
        "hourglass_top",
        "house",
        "import_contacts",
        "insert_invitation",
        "insights",
        "language",
        "local_florist",
        "local_grocery_store",
        "local_shipping",
        "location_city",
        "luggage",
        "mail",
        "menu_book",
        "mood",
        "mood_bad",
        "movie",
        "music_note",
        "nature",
        "nature_people",
        "navigation",
        "notifications_active",
        "panorama",
        "payment",
        "people_alt",
        "person",
        "pets",
        "photo_camera",
        "place",
        "portrait",
        "public",
        "query_builder",
        "remove_circle",
        "restaurant",
        "school",
        "science",
        "settings",
        "shopping_cart",
        "single_bed",
        "spa",
        "sports_baseball",
        "sports_esports",
        "sports_tennis",
        "thumb_down",
        "thumb_up",
        "timer",
        "visibility",
        "volume_up",
        "warning_amber",
        "watch",
        "waves",
        "wb_sunny",
        "weekend",
        "whatshot",
      ],
    };
  },
  validations: {
    name: {
      required,
    },
    startFrom: {
      required,
      minValue: minValue(1),
    },
    endOn: {
      required,
      minValue: minValue(1),
    },
    step: {
      required,
      minValue: minValue(1),
    },
  },
  created() {
    if (this.editForm) {
      this.getStorageInfo();
    }
  },
  computed: {
    ...mapGetters(["isIos", "isPremium"]),
    disableMode() {
      return !this.isPremium;
    },
    editForm() {
      return this.$route.meta.edit;
    },
  },
  mounted() {
    document.title = "Добавление копилки";
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = true;
        if (this.editForm) {
          axios
            .post("/storage/save", {
              storage: {
                id: this.$route.params?.id,
                name: this.name,
                icon: this.selectedIcon,
                color: this.selectedColor,
                start_from: this.startFrom,
                end_on: this.endOn,
                mode: this.mode,
                step: this.step,
              },
            })
            .then((r) => {
              this.loading = false;
              if (r.data.result === "success") {
                this.$router.push({ name: "Home" });
              }
            });
        } else {
          axios
            .post("/storage/add", {
              storage: {
                name: this.name,
                icon: this.selectedIcon,
                color: this.selectedColor,
                start_from: this.startFrom,
                end_on: this.endOn,
                mode: this.mode,
                step: this.step,
              },
            })
            .then((r) => {
              this.loading = false;
              if (r.data.result === "success") {
                this.$router.push({ name: "Home" });
                if (this.isPremium !== true) {
                  (async () => {
                    const interstitial = new InterstitialAd({
                      adUnitId: "ca-app-pub-8690020955174405/3951486906",
                    });
                    await interstitial.load();
                    await interstitial.show();
                  })();
                }
              }
            });
        }
      } else {
        this.loading = false;
      }
    },
    getStorageInfo() {
      axios.get(`/storage/${this.$route.params.id}`).then((r) => {
        if (r.data.result === "success") {
          this.selectedColor = r.data.data.color;
          this.selectedIcon = r.data.data.icon;
          this.name = r.data.data.name;
          this.startFrom = r.data.data.start_from;
          this.endOn = r.data.data.end_on;
          this.mode = r.data.data.mode;
          this.step = r.data.data.step;
          document.title = "Редактирование копилки";
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.storage-form {
  &__text {
    font-weight: bold;
  }
  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    .svg-icon {
      margin-right: auto;
      margin-left: 8px;
    }
  }
  &__btns {
    padding-bottom: 16px;
  }
  &__overflow {
    height: 200px;
    overflow-y: scroll;
    margin-bottom: 24px;
  }
  &__label {
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    color: #09101d;
    display: inline-block;
    background: #ffffff;
    transition: color 0.4s ease;
    border-radius: 8px;
    margin-bottom: 12px;
  }
  &__colors {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 8px;
    align-items: center;
    margin-bottom: 32px;
  }
  &__color {
    cursor: pointer;
    width: 40px;
    height: 40px;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .svg-icon {
      fill: #ffffff;
    }
  }
  &__icons {
    display: grid;
    flex-wrap: wrap;
    gap: 8px;
    grid-template-columns: repeat(6, 1fr);
    margin-bottom: 24px;
  }

  &__icon {
    padding: 8px;
    border: 1px solid transparent;
    cursor: pointer;
    border-radius: 100%;
    transition: 0.4s ease;
    display: flex;
    align-items: center;
    justify-content: center;

    &:before {
      content: "";
      float: left;
      width: 1px;
      margin-left: -1px;
      padding-top: 100%;
    }
    &:after {
      content: "";
      display: table;
      clear: both;
    }

    &:hover {
      border-color: #333333;
    }

    &.active {
      border-color: #333333;
    }
  }
}
.dark {
  .storage-form {
    &__icon {
      .svg-icon {
        fill: #fff;
      }
      &.active {
        border-color: #ffffff;
      }
    }
    &__text {
      color: $blueGray;
    }
    &__label {
      color: $blueGray;
      background: transparent;
      margin-bottom: 16px;
    }
  }
}
</style>
