<template>
  <base-page :has-header="true" class="login-page">
    <base-header
      @click.native="$router.push({ name: 'Login' })"
      :no-scroll="true"
    >
      <template #back>
        <svg-icon name="chevron-left" />
      </template>
      <template #default>Назад</template>
    </base-header>
    <base-container>
      <div v-show="!submited" class="login-page__wrapper">
        <div class="login-page__hello">
          <img
            src="~@/assets/img/logo.svg"
            class="login-page__logo"
            alt="Копилка 365 - Накопить за год легко"
          />
          <h1 class="login-page__title">Копилка 365 - Накопить за год легко</h1>
        </div>
        <base-alert class="alert--danger" center>
          Вход только для тех, кто уже регистрировался ранее по номеру телефона
        </base-alert>
        <form class="login-page__form" method="POST" @submit.prevent="submit">
          <base-phone-input
            label="Телефон"
            :compact="true"
            ref="phoneInput"
            v-model.trim="$v.phone.$model"
            :error="{
              status: $v.phone.$error && hasErrors,
              text: 'Введите номер телефона',
            }"
            :block="true"
            placeholder="Введите номер телефона"
          />
          <base-button
            :loading="loading"
            class="btn--primary btn--block"
            button-type="submit"
            >Войти</base-button
          >
          <base-alert v-if="hasErrors" class="alert--danger" center>
            {{ errorText }}
          </base-alert>
          <router-link class="login-page__link" :to="{ name: 'Login' }"
            >Другие варианты входа</router-link
          >
        </form>
      </div>
      <div v-show="submited" class="login-page__wrapper">
        <p class="login-page__title">Введите код из смс</p>
        <p class="login-page__desc">Код выслан на номер {{ phone }}</p>
        <CodeInput
          :class="{ 'login-page__code--error': codeError }"
          ref="smsCode"
          :fieldWidth="55"
          :fieldHeight="55"
          :fields="4"
          class="login-page__code input"
          v-on:change="codeError = false"
          v-on:complete="submitSmsCode"
        />
        <div v-if="counter > 0" class="login-page__counter">
          Отправить код повторно можно через: {{ counter }} с.
        </div>
        <div v-else class="login-page__counter">
          <a href="#" @click.prevent="sendSms">Выслать код повторно</a>
        </div>
        <div v-if="codeError" class="error error--center">Неверный код</div>
      </div>
    </base-container>
  </base-page>
</template>

<script>
import BaseContainer from "@/components/base/BaseContainer";
import BasePhoneInput from "@/components/base/inputs/BasePhoneInput";
import BaseButton from "@/components/base/BaseButton";
import BaseHeader from "@/components/base/BaseHeader";
import BasePage from "@/components/base/BasePage";
import CodeInput from "vue-verification-code-input";
import axios from "axios";
import store from "@/store";
import router from "@/router";
import SvgIcon from "@/components/SvgIcon";
import { required, minLength } from "vuelidate/lib/validators";
import BaseAlert from "@/components/base/BaseAlert";
export default {
  name: "LoginPage",
  components: {
    BaseAlert,
    SvgIcon,
    BaseHeader,
    BaseButton,
    BasePhoneInput,
    BaseContainer,
    CodeInput,
    BasePage,
  },
  data() {
    return {
      submited: false,
      phone: null,
      hasErrors: false,
      errorText: null,
      codeError: false,
      loading: false,
      counter: parseInt(window.localStorage.getItem("counter")) || 60,
      counterId: null,
      serverError: null,
    };
  },
  mounted() {
    this.$refs.phoneInput.$el.querySelector("input").focus();
    document.title = "Авторизация через телефон";
    if (this.submited) {
      this.startCounter();
    }
  },
  beforeDestroy() {
    clearInterval(this.counterId);
  },
  validations: {
    phone: {
      required,
      minLength: minLength(17),
    },
    password: {
      required,
    },
  },
  methods: {
    startCounter() {
      clearInterval(this.counterId);
      this.counterId = setInterval(() => {
        if (this.counter > 0) {
          this.counter = this.counter - 1;
          window.localStorage.setItem("counter", this.counter.toString());
        } else {
          clearInterval(this.counterId);
          this.counter = 0;
        }
      }, 1000);
    },
    sendSms() {
      axios.post("login/sms", { phone: this.phone }).then(() => {
        this.counter = 60;
        this.startCounter();
      });
    },
    submit() {
      this.$v.$touch();
      if (!this.$v.phone.$invalid) {
        this.loading = true;
        axios
          .post("login/sms", { phone: this.phone })
          .then((r) => {
            this.loading = false;
            if (r.data.result === "success") {
              this.submited = true;
              this.startCounter();
              this.$nextTick(function () {
                this.$refs.smsCode.$el.querySelector("input").focus();
              });
            } else {
              this.hasErrors = true;
              this.errorText = r.data.errorText;
            }
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        this.hasErrors = true;
        this.loading = false;
      }
    },
    submitSmsCode(values) {
      if (values.trim().length === 4) {
        axios
          .post("/login", { phone: this.phone, smsCode: values })
          .then((loginResponse) => {
            if (
              loginResponse.data.result === "success" &&
              loginResponse.data.token
            ) {
              store.commit("LogIn", loginResponse.data.token);
              axios.get("/user").then((r) => {
                store.commit("SetUser", r.data);
                if (loginResponse.data.registered === 0) {
                  router.push("/registration");
                } else {
                  router.push("/home");
                }
              });
            } else {
              this.codeError = true;
            }
          })
          .catch(() => {
            this.codeError = true;
          });
      }
    },
  },
};
</script>

<style lang="scss">
.login-page {
  &__counter {
    font-size: 14px;
    text-align: center;
    color: $grey;
    margin-top: 32px;

    a {
      color: $primary;
      font-size: 16px;
      text-decoration: none;
    }
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10%;
  }
  &__hello {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 32px;
  }
  &__link {
    display: block;
    text-align: center;
    margin-top: 32px;
    font-size: 16px;
    color: $primary;
    text-decoration: none;
    transition: color 0.4s ease;

    &:hover {
      color: #000;
      text-decoration: underline;
    }
  }
  &__logo {
    width: 110px;
    height: 110px;
  }
  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 150%;
    text-align: center;
    color: $secondary;
    margin-top: 16px;
    margin-bottom: 32px;
  }
  &__desc {
    font-size: 16px;
    line-height: 1;
    text-align: center;
    color: $grey;
    margin-top: 8px;
    margin-bottom: 16px;
  }
  &__form {
    width: 100%;
    margin-top: 16px;

    .alert {
      margin-top: 16px;
    }
  }
  &__code {
    margin: 0 auto;
    width: 250px !important;
    .react-code-input {
      display: flex;
      justify-content: space-between;
    }
    input {
      border: 1px solid #cad3ef !important;
      border-radius: 12px !important;
      background-color: #ebf2f7 !important;
      color: #000000 !important;
      font-size: 18px !important;
      font-family: "Open Sans", sans-serif !important;
      &:focus {
        border-color: $primary !important;
      }
    }
    &--error {
      input {
        border-color: $red !important;
        background-color: #ffffff !important;
        &:focus {
          border-color: $red !important;
        }
      }
    }
  }
  &__btns {
    margin-top: 16px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;

    &--full {
      grid-template-columns: auto;
    }
  }
}
.dark {
  .login-page {
    &__title {
      color: $blueGray;
    }
  }
}
@media (min-width: 1220px) {
  .login-page {
    &__wrapper {
      max-width: 400px;
      margin: 0 auto;
    }
  }
}
</style>
