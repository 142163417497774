import "@/css/reset.css";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import Vuelidate from "vuelidate";
import VueConfetti from "vue-confetti";
import { StatusBar, Style } from "@capacitor/status-bar";
import { Device } from "@capacitor/device";
import { App as CapacitorApp } from "@capacitor/app";
import { InAppPurchase2 } from "@ionic-native/in-app-purchase-2";
import myStore from "@/store";

Vue.use(VueConfetti);
Vue.use(Vuelidate);

Vue.config.productionTip = false;

axios.interceptors.response.use(undefined, function (error) {
  if (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      store.commit("LogOut");
      return router.push("/login");
    }
  }
});

const token = localStorage.getItem("token");

if (token) {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
}
axios.defaults.baseURL = "https://api.metod-365.ru/api";
axios.defaults.headers["Content-Type"] = "application/json";

function registerPremium() {
  InAppPurchase2.register({
    id: "premium",
    type: InAppPurchase2.NON_CONSUMABLE,
  });
  InAppPurchase2.refresh();
}
function setupPremiumListeners() {
  InAppPurchase2.when("premium")
    .approved((p) => p.verify())
    .verified((p) => {
      p.finish();
    });

  InAppPurchase2.when("product").updated((p) => {
    myStore.commit("SetPremiumProduct", {
      price: p.price,
      state: p.state,
    });
  });
}

Device.getInfo().then((device) => {
  if (device.platform !== "web") {
    registerPremium();
    setupPremiumListeners();
  }
});

new Vue({
  router,
  store,
  render: (h) => h(App),
  data() {
    return {
      twiceClicked: false,
    };
  },
  methods: {
    deviceInfo() {
      Device.getInfo().then((device) => {
        if (device.platform !== "web") {
          if (store.state.theme === "dark") {
            StatusBar.setStyle({ style: Style.Dark });
            StatusBar.setBackgroundColor({ color: "#15202b" });
          } else {
            StatusBar.setStyle({ style: Style.Light });
            StatusBar.setBackgroundColor({ color: "#ffffff" });
          }
        }
        this.$store.commit("SetPlatform", device.platform);
      });
    },
  },
  mounted() {
    CapacitorApp.addListener("backButton", () => {
      if (this.twiceClicked) {
        CapacitorApp.exitApp();
      } else {
        this.twiceClicked = true;
      }
      setTimeout(() => {
        this.twiceClicked = false;
      }, 300); //время очистки
      this.$router.go(-1);
    });
    this.deviceInfo();
    let iPhone = /iPhone/.test(navigator.userAgent) && !window.MSStream;
    let aspect = window.screen.width / window.screen.height;
    if (iPhone && aspect.toFixed(3) === "0.462") {
      if (!document.body.classList.contains("ios-stripe")) {
        document.body.classList.add("ios-stripe");
      }
    }
  },
  beforeDestroy() {
    CapacitorApp.removeAllListeners();
  },
}).$mount("#app");
