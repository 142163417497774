<template>
  <div
    class="input-group"
    :class="{
      'input-group--error': error.status,
      'input-group--block': block,
      'input-group--focused': focused,
      'input-group--compact': compact,
    }"
  >
    <div class="input-group__wrapper">
      <label v-if="label && label.length > 0" class="input-group__label"
        >{{ label }} <span v-if="required">*</span></label
      >
      <input
        class="input-group__input"
        :type="inputType"
        :placeholder="placeholder"
        :required="required ? 'required' : false"
        :value="value"
        :disabled="disabled"
        @click="click"
        @focus="focused = true"
        @blur="focused = false"
        @input="$emit('input', $event.target.value)"
        @change="$emit('change', $event.target.value)"
      />
    </div>
    <div v-if="error.status" class="input-group__error">
      {{ error.text }}
    </div>
    <p v-if="description" class="input-group__input-description">
      {{ description }}
    </p>
  </div>
</template>
<script>
import inputMixins from "@/components/base/inputs/inputMixins";
export default {
  name: "BaseInput",
  mixins: [inputMixins],
  methods: {
    click() {
      this.$emit("click");
    },
  },
};
</script>

<style scoped lang="scss">
@import "inputStyles";
</style>
