<template>
  <base-page>
    <base-loader :loading="loading" />
    <base-header
      @click.native="$router.push({ name: 'Login' })"
      :no-scroll="true"
    >
      <template #back>
        <svg-icon name="chevron-left" />
      </template>
      <template #default>Назад</template>
    </base-header>
    <base-container>
      <p>
        Сейчас вы будете перенаправлены на страницу авторизации Google, если
        этого не случилось нажмите на кнопку ниже
      </p>
      <base-button
        :loading="loading"
        @click.native="GoogleAuth"
        class="btn--danger btn--block"
        >Войти через Google</base-button
      >
    </base-container>
  </base-page>
</template>

<script>
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import BasePage from "@/components/base/BasePage";
import BaseLoader from "@/components/base/BaseLoader";
import BaseHeader from "@/components/base/BaseHeader";
import SvgIcon from "@/components/SvgIcon";
import BaseContainer from "@/components/base/BaseContainer";
import BaseButton from "@/components/base/BaseButton";
import axios from "axios";
import store from "@/store";
import router from "@/router";
export default {
  name: "LoginPageGoogle",
  components: {
    BaseButton,
    BaseContainer,
    SvgIcon,
    BaseHeader,
    BaseLoader,
    BasePage,
  },
  created() {
    this.GoogleAuth();
  },
  methods: {
    GoogleAuth() {
      GoogleAuth.signIn().then((r) => {
        axios
          .post("/login-google", { googleId: r.id })
          .then((loginResponse) => {
            if (
              loginResponse.data.result === "success" &&
              loginResponse.data.token
            ) {
              store.commit("LogIn", loginResponse.data.token);
              axios.get("/user").then((r) => {
                store.commit("SetUser", r.data);
                this.loading = false;
              });
              if (loginResponse.data.registered === 0) {
                router.push("/registration");
              } else {
                router.push("/home");
              }
            }
          });
      });
    },
  },
  data() {
    return {
      loading: false,
    };
  },
};
</script>

<style scoped>
.dark p {
  color: #fff;
}
</style>
