<template>
  <div id="app" :class="appTheme">
    <router-view />
    <main-nav />
  </div>
</template>

<script>
import MainNav from "@/components/MainNav";
import { mapGetters, mapState } from "vuex";
import store from "@/store";
import { SplashScreen } from "@capacitor/splash-screen";
export default {
  components: { MainNav },
  data() {
    return {
      premium: {},
    };
  },
  mounted() {
    SplashScreen.hide();
    this.$store.commit(
      "SetIsMobile",
      window.matchMedia("(max-width: 1199px)").matches
    );
    if (this.isAuthenticated) {
      store.dispatch("UPDATE_USER");
    }
  },
  computed: {
    ...mapGetters(["isAuthenticated", "isIos", "isAndroid"]),
    ...mapState(["user", "isMobile", "theme"]),
    appTheme() {
      if (this.theme === "dark") {
        return "dark";
      }
      return "light";
    },
  },
};
</script>

<style lang="scss">
@include font-face("Roboto", "~@/assets/fonts/Roboto-Regular", 400);
@include font-face("Roboto", "~@/assets/fonts/Roboto-Bold", 600);

* {
  font-family: Roboto, sans-serif;
  font-weight: 400;
}

*,
*:focus {
  outline: none;
}

body {
  -webkit-overflow-scrolling: touch !important;
  position: relative;
}

#app {
  position: relative;
  -webkit-overflow-scrolling: touch !important;
}

a {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.error {
  font-size: 14px;
  color: $red;
  font-weight: 600;
  margin-top: 8px;

  &--center {
    text-align: center;
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.2s;
  transition-property: height, opacity;
  transition-timing-function: ease;
  overflow: hidden;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

#app.dark {
  background-color: #15202b !important;
}
.dark {
  .bottom-sheet__card {
    background: #192734 !important;
  }
  .bottom-sheet__bar {
    background: $blueGray !important;
  }
}

.mb-16 {
  margin-bottom: 16px;
}
</style>
