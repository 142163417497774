<template>
  <base-page class="settings-page" has-menu>
    <base-header>
      <template #default>Настройки</template>
    </base-header>
    <base-container>
      <div class="settings-page__item">
        Получать уведомления
        <base-toggle-ios
          v-model="user.notif"
          @change="updateSettings"
          :id="1"
          :true-value="1"
          :false-value="0"
        />
      </div>
      <div v-if="user.notif" class="settings-page__item">
        <base-select
          v-model="user.notif_time"
          @change.native="updateSettings"
          block
          compact
          :options="hours"
          label="Время уведомления"
        />
      </div>
      <div class="settings-page__item">
        Пополнять копилку раз в день
        <base-toggle-ios
          v-model="user.one_in_day"
          @change="updateSettings"
          :id="2"
          :true-value="1"
          :false-value="0"
        />
      </div>
      <base-select
        class="settings-page__select"
        :block="true"
        :compact="true"
        label="Тема приложения"
        v-model="appTheme"
        :options="[
          { name: 'Светлая', value: 'light' },
          { name: 'Темная', value: 'dark' },
        ]"
      />
      <premium-window
        title="Новые возможности с Premium за"
        v-if="!isPremium"
      />
    </base-container>
  </base-page>
</template>

<script>
import BaseHeader from "@/components/base/BaseHeader";
import BasePage from "@/components/base/BasePage";
import BaseContainer from "@/components/base/BaseContainer";
import BaseToggleIos from "@/components/base/BaseToggleIos";
import { mapGetters, mapState } from "vuex";
import axios from "axios";
import BaseSelect from "@/components/base/inputs/BaseSelect";
import PremiumWindow from "@/components/PremiumWindow";
import { StatusBar, Style } from "@capacitor/status-bar";
export default {
  name: "Home",
  components: {
    PremiumWindow,
    BaseSelect,
    BaseToggleIos,
    BaseContainer,
    BasePage,
    BaseHeader,
  },
  data() {
    return {
      appTheme: this.$store.state.theme,
      hours: [
        { name: "01:00", value: "01:00" },
        { name: "02:00", value: "02:00" },
        { name: "03:00", value: "03:00" },
        { name: "04:00", value: "04:00" },
        { name: "05:00", value: "05:00" },
        { name: "06:00", value: "06:00" },
        { name: "07:00", value: "07:00" },
        { name: "08:00", value: "08:00" },
        { name: "09:00", value: "09:00" },
        { name: "10:00", value: "10:00" },
        { name: "11:00", value: "11:00" },
        { name: "12:00", value: "12:00" },
        { name: "13:00", value: "13:00" },
        { name: "14:00", value: "14:00" },
        { name: "15:00", value: "15:00" },
        { name: "16:00", value: "16:00" },
        { name: "17:00", value: "17:00" },
        { name: "18:00", value: "18:00" },
        { name: "19:00", value: "19:00" },
        { name: "20:00", value: "20:00" },
        { name: "21:00", value: "21:00" },
        { name: "22:00", value: "22:00" },
        { name: "23:00", value: "23:00" },
      ],
    };
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters(["isPremium"]),
  },
  mounted() {
    document.title = "Настройки";
  },
  watch: {
    appTheme: function (theme) {
      this.$store.commit("SetTheme", theme);
      document.addEventListener(
        "deviceready",
        () => {
          if (theme === "dark") {
            StatusBar.setStyle({ style: Style.Dark });
            StatusBar.setBackgroundColor({ color: "#15202b" });
          } else {
            StatusBar.setStyle({ style: Style.Light });
            StatusBar.setBackgroundColor({ color: "#ffffff" });
          }
        },
        false
      );
    },
  },
  methods: {
    updateSettings() {
      axios.post("/user/update", {
        id: this.user.id,
        notif: this.user.notif,
        notif_time: this.user.notif_time,
        one_in_day: this.user.one_in_day,
      });
    },
  },
};
</script>

<style lang="scss">
.settings-page {
  &__select {
    margin-top: 32px;
  }
  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    font-size: 16px;
    padding: 12px 8px 8px 0;
  }
}
.dark {
  .settings-page {
    &__item {
      color: $blueGray;
    }
  }
}
</style>
