<template>
  <div v-if="!market" class="market">
    <img
      @click="goToMarket"
      class="market__img"
      src="~@/assets/img/logo-circle.svg"
      alt=""
    />
    <div class="market__title" @click="goToMarket">
      Привет! Оставь нам, пожалуйста, отзыв нажав на это сообщение ^__^
    </div>
    <div @click="closeMarketMessage" class="market__action">
      <svg-icon name="clear" />
    </div>
  </div>
</template>

<script>
import SvgIcon from "@/components/SvgIcon";
export default {
  name: "MarketThnx",
  components: { SvgIcon },
  data() {
    return {
      market: window.localStorage.getItem("market") || false,
    };
  },
  methods: {
    goToMarket() {
      location.href =
        "https://play.google.com/store/apps/details?id=ru.metod365.app";
    },
    closeMarketMessage() {
      window.localStorage.setItem("market", "true");
      this.market = true;
    },
  },
};
</script>

<style lang="scss">
.market {
  display: grid;
  grid-template-columns: 60px auto 40px;
  padding: 8px 16px;
  column-gap: 16px;
  background: #03a9f4;
  align-items: center;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  margin-bottom: 16px;
  margin-top: 16px;
  color: #fff;
  &__img {
    width: 60px;
    height: 60px;
  }
  &__action {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.3);
    transition: background-color 0.4s ease;

    .svg-icon {
      fill: #fff;
    }
  }
}
</style>
