<template>
  <base-page>
    <base-header
      :no-scroll="true"
      @click.native="
        $router.push({
          name: 'Profile',
        })
      "
    >
      <template #back>
        <svg-icon name="chevron-left" />
      </template>
      <template #default>Изменение e-mail</template>
    </base-header>
    <base-container>
      <form @submit.prevent="submit" action="#">
        <base-input
          v-if="user.email"
          :block="true"
          :disabled="true"
          :value="user.email"
          label="Ваш текущий e-mail"
        />
        <base-input
          :block="true"
          @change="emailWasSended = false"
          label="Введите новый e-mail"
          :error="{
            status: $v.newEmail.$error,
            text: 'Введите e-mail',
          }"
          v-model.trim="$v.newEmail.$model"
        />
        <base-input
          v-show="emailWasSended"
          label="Код из письма"
          :error="{
            status: $v.emailCode.$error,
            text: 'Введите код из 8 цифр',
          }"
          v-model.trim="$v.emailCode.$model"
        />
        <base-button
          :loading="loading"
          button-type="submit"
          class="btn--primary btn--block"
        >
          <template v-if="emailWasSended">Сохранить</template>
          <template v-else>Получить код на почту</template>
        </base-button>
      </form>
      <base-alert
        v-if="serverError"
        style="margin-top: 32px"
        type="danger"
        :center="true"
      >
        {{ serverError }}
      </base-alert>
    </base-container>
  </base-page>
</template>

<script>
import BasePage from "@/components/base/BasePage";
import BaseHeader from "@/components/base/BaseHeader";
import SvgIcon from "@/components/SvgIcon";
import BaseContainer from "@/components/base/BaseContainer";
import { mapState } from "vuex";
import {
  required,
  minLength,
  maxLength,
  numeric,
  email,
} from "vuelidate/lib/validators";
import BaseButton from "@/components/base/BaseButton";
import BaseInput from "@/components/base/inputs/BaseInput";
import axios from "axios";
import BaseAlert from "@/components/base/BaseAlert";
export default {
  name: "ProfileEmailPage",
  components: {
    BaseAlert,
    BaseInput,
    BaseButton,
    BaseContainer,
    SvgIcon,
    BaseHeader,
    BasePage,
  },
  data() {
    return {
      newEmail: null,
      emailCode: null,
      emailWasSended: false,
      loading: false,
      serverError: null,
    };
  },
  validations: {
    newEmail: {
      required,
      email,
    },
    emailCode: {
      minLength: minLength(8),
      maxLength: maxLength(8),
      required,
      numeric,
    },
  },
  computed: {
    ...mapState(["user"]),
  },
  mounted() {
    document.title = "Изменение e-mail";
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (!this.emailWasSended) {
        if (!this.$v.newEmail.$invalid) {
          this.loading = true;
          axios
            .post("/user/update-email-code", { email: this.newEmail })
            .then((r) => {
              this.loading = false;
              if (r.data.result === "success") {
                this.emailWasSended = true;
                this.serverError = null;
              } else {
                this.serverError = r.data.errorText;
              }
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          this.loading = false;
        }
      } else {
        if (!this.$v.newEmail.$invalid && !this.$v.emailCode.$invalid) {
          this.loading = true;
          axios
            .post("/user/update-email", {
              email: this.newEmail,
              emailCode: this.emailCode,
            })
            .then((r) => {
              this.loading = false;
              if (r.data.result === "success") {
                this.$router.push({ name: "Profile" });
              } else {
                this.serverError = r.data.errorText;
              }
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          this.loading = false;
        }
      }
    },
  },
};
</script>

<style scoped></style>
