<template>
  <base-page :has-header="true" class="login-page">
    <base-header
      @click.native="$router.push({ name: 'Login' })"
      :no-scroll="true"
    >
      <template #back>
        <svg-icon name="chevron-left" />
      </template>
      <template #default>Назад</template>
    </base-header>
    <base-container>
      <div class="login-page__wrapper">
        <div class="login-page__hello">
          <img
            src="~@/assets/img/logo.svg"
            class="login-page__logo"
            alt="Копилка 365 - Накопить за год легко"
          />
          <h1 class="login-page__title">Копилка 365 - Накопить за год легко</h1>
        </div>
        <form
          class="login-page__form"
          method="POST"
          @submit.prevent="submitEmailForm"
        >
          <div v-show="!emailSubmited">
            <base-input
              label="E-mail"
              :compact="true"
              v-model.trim="$v.email.$model"
              :error="{
                status: $v.email.$error,
                text: 'Введите e-mail',
              }"
              :block="true"
              placeholder="Введите e-mail"
            />
            <base-input
              label="Пароль"
              :compact="true"
              v-show="emailLoginType === 'login'"
              v-model.trim="$v.password.$model"
              :error="{
                status: $v.password.$error,
                text: 'Введите пароль',
              }"
              :block="true"
              input-type="password"
              placeholder="Введите пароль"
            />
            <base-button
              :loading="loading"
              class="btn--primary btn--block"
              button-type="submit"
            >
              <template v-if="emailLoginType === 'login'"> Войти </template>
              <template v-else-if="emailLoginType === 'recover'">
                Сбросить пароль
              </template>
              <template v-else> Зарегистрироваться </template>
            </base-button>
            <div
              class="login-page__btns"
              :class="{ 'login-page__btns--full': emailLoginType !== 'login' }"
            >
              <base-button
                class="btn--secondary btn--block"
                button-type="button"
                @click.native="
                  emailLoginType =
                    emailLoginType === 'login' ? 'register' : 'login';
                  serverError = null;
                "
              >
                <template v-if="emailLoginType === 'login'">
                  Нет аккаунта
                </template>
                <template v-else> Есть аккаунт </template>
              </base-button>
              <base-button
                v-if="emailLoginType === 'login'"
                @click.native="emailLoginType = 'recover'"
                class="btn--secondary btn--block"
                button-type="button"
              >
                Забыл пароль
              </base-button>
            </div>
          </div>
          <div v-show="emailSubmited">
            <base-alert v-if="!emailRecovered" type="primary" :center="true">
              Вы успешно зарегистрированы, вам отправлено письмо с
              подтверждением электронного ящика
            </base-alert>
            <base-alert v-if="emailRecovered" type="primary" :center="true">
              Ваш пароль успешно сброшен и отправлен на ваш e-mail
            </base-alert>
            <base-button
              @click.native="
                emailSubmited = false;
                emailLoginType = 'login';
              "
              button-type="button"
              class="btn--primary btn--block"
              >Перейти на форму входа</base-button
            >
          </div>
          <router-link :to="{ name: 'Login' }" class="login-page__link" href="#"
            >Другие варианты входа</router-link
          >
          <base-alert :center="true" type="danger">{{
            serverError
          }}</base-alert>
        </form>
      </div>
    </base-container>
  </base-page>
</template>

<script>
import BaseContainer from "@/components/base/BaseContainer";
import BaseButton from "@/components/base/BaseButton";
import BaseHeader from "@/components/base/BaseHeader";
import BasePage from "@/components/base/BasePage";
import axios from "axios";
import store from "@/store";
import router from "@/router";
import SvgIcon from "@/components/SvgIcon";
import BaseInput from "@/components/base/inputs/BaseInput";
import { required, email } from "vuelidate/lib/validators";
import BaseAlert from "@/components/base/BaseAlert";
export default {
  name: "LoginPage",
  components: {
    BaseAlert,
    BaseInput,
    SvgIcon,
    BaseHeader,
    BaseButton,
    BaseContainer,
    BasePage,
  },
  data() {
    return {
      emailSubmited: false,
      emailRecovered: false,
      email: null,
      password: null,
      emailLoginType: "login",
      hasErrors: false,
      codeError: false,
      loading: false,
      serverError: null,
    };
  },
  mounted() {
    document.title = "Авторизация через e-mail";
  },
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
    },
  },
  methods: {
    submitEmailForm() {
      this.$v.$touch();
      if (this.emailLoginType === "login") {
        if (!this.$v.email.$invalid && !this.$v.password.$invalid) {
          this.loading = true;
          axios
            .post("/login-by-email", {
              email: this.email,
              password: this.password,
            })
            .then((loginResponse) => {
              this.loading = false;
              if (
                loginResponse.data.result === "success" &&
                loginResponse.data.token
              ) {
                store.commit("LogIn", loginResponse.data.token);
                axios.get("/user").then((r) => {
                  store.commit("SetUser", r.data);
                  if (loginResponse.data.registered === 0) {
                    router.push("/registration");
                  } else {
                    router.push("/home");
                  }
                });
              } else if (loginResponse.data.result === "error") {
                this.loading = false;
                this.serverError = loginResponse.data.errorText;
              }
            })
            .catch(() => {
              this.loading = false;
            });
        }
      }
      if (this.emailLoginType === "register") {
        if (!this.$v.email.$invalid) {
          this.loading = true;
          axios
            .post("/register-by-email", {
              email: this.email,
            })
            .then((loginResponse) => {
              this.loading = false;
              if (loginResponse.data.result === "success") {
                this.emailSubmited = true;
              } else if (loginResponse.data.result === "error") {
                this.serverError = loginResponse.data.errorText;
              }
            })
            .catch(() => {
              this.loading = false;
            });
        }
      }
      if (this.emailLoginType === "recover") {
        if (!this.$v.email.$invalid) {
          this.loading = true;
          axios
            .post("/user/recover-by-email", {
              email: this.email,
            })
            .then((loginResponse) => {
              this.loading = false;
              if (loginResponse.data.result === "success") {
                this.emailSubmited = true;
                this.emailRecovered = true;
              } else if (loginResponse.data.result === "error") {
                this.serverError = loginResponse.data.errorText;
              }
            })
            .catch(() => {
              this.loading = false;
            });
        }
      }
    },
  },
};
</script>

<style lang="scss">
.login-page {
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10%;
  }
  &__hello {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 32px;

    &--mini {
      flex-direction: row;
      align-items: center;
      display: grid;
      grid-template-columns: 50px auto;
      gap: 16px;

      .login-page__title {
        text-align: left;
        margin: 0;
      }

      .login-page__logo {
        width: 50px;
        height: 50px;
      }
    }
  }
  &__link {
    display: block;
    text-align: center;
    margin-top: 32px;
    font-size: 16px;
    color: $primary;
    text-decoration: none;
    transition: color 0.4s ease;

    &:hover {
      color: #000;
      text-decoration: underline;
    }
  }
  &__logo {
    width: 110px;
    height: 110px;
  }
  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 150%;
    text-align: center;
    color: $secondary;
    margin-top: 16px;
    margin-bottom: 32px;
  }
  &__desc {
    font-size: 16px;
    line-height: 1;
    text-align: center;
    color: $grey;
    margin-top: 8px;
    margin-bottom: 16px;
  }
  &__form {
    width: 100%;

    .alert {
      margin-top: 16px;
    }
  }
  &__btns {
    margin-top: 16px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;

    &--full {
      grid-template-columns: auto;
    }
  }
}
.dark {
  .login-page {
    &__title {
      color: $blueGray;
    }
  }
}
</style>
