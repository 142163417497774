<template>
  <div
    class="input-group"
    :class="{
      'input-group--error': error.status,
      'input-group--block': block,
      'input-group--focused': focused,
      'input-group--compact': compact,
    }"
  >
    <div class="input-group__wrapper">
      <label v-if="label && label.length > 0" class="input-group__label"
        >{{ label }} <span v-if="required">*</span></label
      >
      <input
        class="input-group__input"
        v-imask="mask"
        :type="inputType"
        :placeholder="placeholder"
        :required="required ? 'required' : false"
        :value="value"
        @focus="focused = true"
        @blur="focused = false"
        @accept="onAccept"
        @complete="onComplete"
      />
    </div>
    <div v-if="error.status" class="input-group__error">
      {{ error.text }}
    </div>
    <p v-if="description" class="input-group__input-description">
      {{ description }}
    </p>
  </div>
</template>
<script>
import { IMaskDirective } from "vue-imask";
import inputMixins from "@/components/base/inputs/inputMixins";
export default {
  name: "BaseDateInput",
  mixins: [inputMixins],
  directives: {
    imask: IMaskDirective,
  },
  data() {
    return {
      mask: {
        mask: Date,
        min: new Date(1990, 0, 1),
        max: new Date(2020, 0, 1),
        lazy: false,
      },
    };
  },
  methods: {
    onAccept(e) {
      this.$emit("input", e.detail.value);
    },
    onComplete(e) {
      this.$emit("input", e.detail.value);
    },
  },
};
</script>

<style scoped lang="scss">
@import "inputStyles";
</style>
