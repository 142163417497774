<template>
  <div
    :style="{
      'background-color': currentColor,
    }"
    class="storage-item"
  >
    <div
      class="storage-item__overlay"
      :style="{ width: `${fillPercent}%` }"
    ></div>
    <div class="storage-item__wrapper">
      <div
        @click="
          $router.push({ name: 'SingleStorage', params: { id: id.toString() } })
        "
        class="storage-item__icon"
      >
        <svg-icon
          :style="{
            fill: currentColor,
          }"
          :name="icon"
        />
      </div>
      <div
        @click="
          $router.push({ name: 'SingleStorage', params: { id: id.toString() } })
        "
        class="storage-item__desc"
      >
        <div class="storage-item__name">
          {{ name }}
        </div>
        <div class="storage-item__summ">
          Уже накоплено: {{ convertPrice(summ) }} <br />
          из {{ convertPrice(needSumm) }}
        </div>
      </div>
      <div @click="openActionModal(id)" class="storage-item__actions">
        <svg-icon name="dots" />
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from "@/components/SvgIcon";
import { convertPrice } from "@/helpers";
export default {
  name: "StorageItem",
  components: { SvgIcon },
  props: {
    id: Number,
    icon: String,
    color: String,
    name: [String, Number],
    days: Number,
    summ: Number,
    needSumm: Number,
    archive: Number,
  },
  methods: {
    convertPrice,
    openActionModal(id) {
      this.$emit("openActionModal", id);
    },
  },
  computed: {
    currentColor() {
      return this.archive === 1 ? "#b4b4b4" : this.color;
    },
    fillPercent() {
      return Math.round((this.summ / this.needSumm) * 100);
    },
  },
};
</script>

<style scoped lang="scss">
.storage-item {
  border-radius: 16px;
  position: relative;
  overflow: hidden;

  * {
    user-select: none;
  }

  &__wrapper {
    display: grid;
    grid-template-columns: 60px auto 40px;
    padding: 8px 16px;
    column-gap: 16px;
    align-items: center;
    color: #ffffff;
    text-decoration: none;
    position: relative;
    z-index: 15;
  }

  &__overlay {
    background: rgba(0, 0, 0, 0.2);
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
  }

  &__actions {
    width: 40px;
    height: 40px;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(#000000, 0.3);
    transition: background-color 0.4s ease;

    &:active {
      background: rgba(#000000, 0.6);
    }
  }

  &__name {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 5px;
    line-height: 1.2;
  }

  &__days {
    font-size: 12px;
  }

  &__summ {
    font-size: 12px;
  }

  &__icon {
    width: 60px;
    height: 60px;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;

    .svg-icon {
      fill: #000000;
      width: 30px;
      height: 30px;
    }
  }

  & + .storage-item {
    margin-top: 16px;
  }
}
.dark {
  .storage-item {
    &__icon {
      background: #192734;
    }
  }
}
</style>
