<template>
  <base-page>
    <base-header
      :no-scroll="true"
      @click.native="
        $router.push({
          name: 'Profile',
        })
      "
    >
      <template #back>
        <svg-icon name="chevron-left" />
      </template>
      <template #default>Изменение пароля</template>
    </base-header>
    <base-container>
      <form @submit.prevent="submit" action="#">
        <base-input
          :block="true"
          input-type="password"
          label="Новый пароль"
          :error="{
            status: $v.password.$error,
            text: 'Введите пароль',
          }"
          v-model.trim="$v.password.$model"
        />
        <base-input
          :block="true"
          input-type="password"
          label="Повторите новый пароль"
          :error="{
            status: $v.repeatPassword.$error,
            text: !$v.repeatPassword.sameAsPassword
              ? 'Пароли не совпадают'
              : 'Введите пароль',
          }"
          v-model.trim="$v.repeatPassword.$model"
        />
        <base-button
          :loading="loading"
          button-type="submit"
          class="btn--primary btn--block"
        >
          Сохранить
        </base-button>
      </form>
      <base-alert
        v-if="serverError"
        style="margin-top: 32px"
        type="danger"
        :center="true"
      >
        {{ serverError }}
      </base-alert>
    </base-container>
  </base-page>
</template>

<script>
import BasePage from "@/components/base/BasePage";
import BaseHeader from "@/components/base/BaseHeader";
import SvgIcon from "@/components/SvgIcon";
import BaseContainer from "@/components/base/BaseContainer";
import { mapState } from "vuex";
import { required, sameAs } from "vuelidate/lib/validators";
import BaseButton from "@/components/base/BaseButton";
import BaseInput from "@/components/base/inputs/BaseInput";
import axios from "axios";
import BaseAlert from "@/components/base/BaseAlert";
export default {
  name: "ProfilePasswordPage",
  components: {
    BaseAlert,
    BaseInput,
    BaseButton,
    BaseContainer,
    SvgIcon,
    BaseHeader,
    BasePage,
  },
  data() {
    return {
      password: null,
      repeatPassword: null,
      loading: false,
      serverError: null,
    };
  },
  validations: {
    password: {
      required,
    },
    repeatPassword: {
      required,
      sameAsPassword: sameAs("password"),
    },
  },
  computed: {
    ...mapState(["user"]),
  },
  mounted() {
    document.title = "Изменение пароля";
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (!this.$v.password.$invalid && !this.$v.repeatPassword.$invalid) {
        this.loading = true;
        axios
          .post("/user/update-password", { password: this.password })
          .then((r) => {
            this.loading = false;
            if (r.data.result === "success") {
              this.$router.push({ name: "Profile" });
            } else {
              this.serverError = r.data.errorText;
            }
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped></style>
