<template>
  <div
    class="header"
    ref="header"
    :class="{
      'header--no-scroll': noScroll,
      'header--sticky': sticky,
      'header--pointer': hasBackSlot,
    }"
  >
    <div class="header__back" v-if="hasBackSlot">
      <slot name="back" />
    </div>
    <div class="header__title">
      <slot />
    </div>
    <div v-if="hasActionSlot" class="header__action">
      <slot name="action" />
    </div>
    <div v-if="hasTextSlot" class="header__text">
      <slot name="text" />
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseHeader",
  data() {
    return {
      sticky: false,
      headerTopOffset: null,
    };
  },
  props: {
    noScroll: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.headerTopOffset = this.$refs.header.offsetTop;
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const scrollOffsetTop = window.pageYOffset;
      if (scrollOffsetTop > this.headerTopOffset) {
        if (!this.$refs.header.classList.contains("header--sticky")) {
          this.sticky = true;
        }
      } else {
        this.sticky = false;
      }
    },
  },
  computed: {
    hasActionSlot() {
      return !!this.$slots["action"];
    },
    hasTextSlot() {
      return !!this.$slots["text"];
    },
    hasBackSlot() {
      return !!this.$slots["back"];
    },
  },
};
</script>

<style lang="scss">
.header {
  padding: 16px;
  display: flex;
  align-items: center;
  background: #ffffff;
  user-select: none;
  line-height: 1;
  z-index: 100;
  height: 60px;

  &__title {
    font-size: 26px;
    font-weight: 600;
    color: $secondary;
    transition: font-size 0.4s ease;
    user-select: none;
    span,
    div,
    p {
      font-weight: 600;
    }
  }

  &__action {
    margin-left: auto;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    margin-right: -8px;
    &:focus,
    &:active {
      background: rgba(0, 0, 0, 0.1);
    }

    .svg-icon {
      fill: $primary !important;
    }
  }

  &__text {
    margin-left: auto;
    font-weight: 600;
    color: $primary;
    transition: color 0.4s ease;

    &:active {
      color: $secondary;
    }
  }

  &__back {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -16px;
  }

  &--sticky {
    position: sticky;
    top: 0;
    left: 0;
    box-shadow: 0 0 5px rgb(0 0 0 / 20%);
    z-index: 200;

    .header__title {
      font-size: 18px;
    }
  }

  &--pointer {
    cursor: pointer;
  }

  &--no-scroll {
    .header__title {
      font-size: 18px;
    }
  }
}
.dark {
  .header {
    background-color: #15202b;

    &__title {
      color: $blueGray;
    }
    &__action {
      .svg-icon {
        fill: $primaryDark !important;
      }
    }
  }
}
@media (min-width: 1220px) {
  .header {
    max-width: 1220px;
    margin: 0 auto;
  }
}
</style>
