<template>
  <div class="container">
    <slot />
  </div>
</template>

<script>
export default {
  name: "BaseContainer",
};
</script>

<style scoped lang="scss">
.container {
  max-width: 1220px;
  margin: 0 auto;
  padding: 0 16px;
}
</style>
